.privacy-policy {
    text-align: left !important;
    padding: 5px;
}

.privacy-policy h2 {
    color: #333;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}

.privacy-policy h3 {
    color: #666;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;
}

.privacy-policy p {
    color: #666;
    font-size: 13px;
    margin-bottom: 15px;
}

.privacy-policy li {
    margin-left: -15px;
    color: #666;
    font-size: 13px;
}