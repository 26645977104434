.add-payment-backdrop {
    background-color: #4CA7DF;
    padding-bottom: 15px;
}

.add-payment-method {
    padding: 15px;
}

.add-payment-header {
    text-align: center;
    font-size: 25px;
    color: white;
    margin: 10px;
    margin-bottom: 20px;
}

.payment-add-button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-payment-method form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.add-payment-method form label {
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 12px;
    color: white;
}

.add-payment-method form input[type="text"],
.add-payment-method form select,
.add-payment-method form input[type="file"] {
    font-size: 12px;
    width: 65%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 15px;
    border: 1px solid #ccc;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
    box-sizing: border-box; /* So padding doesn't affect width */
}

.card-number-inputs {
    display: flex;
    justify-content: space-between;
    gap: 10px; /* Space between input fields */
    margin-bottom: 15px;
}

.add-payment-method-submit-container {
    background-image: url('../../../../../../../assets//Dashboard/Profile//components/Cart/containerBackdrop.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
    margin-top: -1px;
    /* margin-bottom: 70px; */
    text-align: right;
    padding: 20px;
}

.add-payment-method-submit {
    font-size: 13px;
    font-weight: bold;
    background-color: #4CA7DF;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    margin-top: 35px;
    margin-right: 20px;
    width: 100px;
}

.add-payment-method-submit:hover {
    background-color: rgb(28, 116, 170);
}

.payment-add-popup-header {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
}

.payment-add-popup-button-container {
    display: flex;
    justify-content: space-between;
    padding: 10px; 
}

.payment-add-popup-delete {
    /* font-weight: bold; */
    font-size: 15px;
    background-color: #d60052;
    color: white;
    width: 85px;
    height: 40px;
    border-radius: 10px;
    border: none;
}

.payment-add-popup-cancel {
    /* font-weight: bold; */
    font-size: 15px;
    background-color: #FFB516;
    color: white;
    width: 85px;
    height: 40px;
    border-radius: 10px;
    border: none;
}

.default-payment-method {
    text-align: right;
    margin-top: 20px;
}

.default-payment-method label {
    font-size: 15px !important;
    color: black !important;
}

.default-payment-method input {
    margin-right: 10px;
}

.add-payment-stripe-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the CardElement horizontally */
    justify-content: center; /* Center the CardElement vertically */
}

.add-payment-stripe-label {
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 12px;
    color: white;
}

.StripeElement {
    background-color: white;
    width: 65%;
    padding: 12px;
    margin-bottom: 10px;
    border-radius: 15px;
    border: 1px solid #ccc;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
}