.payment-details-header {
    margin: 10px;
    margin-left: 15px;
    color: #4A870C;
    font-size: 20px;
    font-weight: 600;
}

.payment-details-information {
    padding: 15px;
    /* border: 2px solid #FFB516; */
    border-radius: 25px;
}

.payment-details-information p {
    /* margin-left: 10px; */
}

.payment-details-additional-service {
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.payment-details-additional-services-container {
    margin-top: 50px;
    border-radius: 25px;
}

.payment-details-additional-services-container h2 {
    color: #4A870C;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
}

.payment-details-additional-services-container p {
}

.payment-details-additional-services-container input {
    width: 80%;
    margin-top: 5px;
    margin-bottom: 5px;
}

.payment-details-special-notes-textarea {
    width: 300px;
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 15px;
    font-size: 13px;
    text-align: center;
}

.payment-details-buttons-container {
    display: flex;
    justify-content: center;
}

.payment-details-buttons-container button {
    margin: 5px;
    margin-top: 10px;
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 14px;
    /* background-color: #47B2E7; */
    color: white;
    width: 100px;
    height: 35px;
    border-radius: 10px;
    border: none;
}

.payment-details-update-button {
    background-color: #47B2E7;
}

.payment-details-contact-support-button {
    background-color: #FFB516;
}

.payment-details-cancel-booking-button {
    background-color: #E9716B;
}

.pagination-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
  }
  
  .pagination-button {
    border: none;
    border-radius: 25px;
    color: white;
    background-color: #47B2E7;
    margin: 11px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    font-weight: 500;
  }
  
  .pagination-button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  .pagination-info {
    font-size: 14px;
    font-weight: bold;
  }
  
  /* Overlay Background */
.booking-cancel-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 8888;
}

/* Modal Box */
.booking-cancel-modal {
    width: 400px;
    background: white;
    border-radius: 15px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    animation: fadeIn 0.3s ease-in-out;
}

/* Title */
.add-pet-modal-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 30px;
}

/* Message */
.add-pet-modal-message {
    font-size: 14px;
    margin-bottom: 20px;
}

/* Button Group */
.modal-button-group {
    display: flex;
    justify-content: space-around;
}

/* Cancel Button */
.booking-cancel-confirm-button {
    background-color: rgb(199, 0, 0);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 15px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.2s ease, transform 0.2s ease;
}

.booking-cancel-confirm-button:hover {
    background-color: darkred;
    transform: scale(1.05);
}

/* Go Back Button */
.booking-cancel-cancel-button {
    background-color: #4CA7DF;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 15px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.2s ease, transform 0.2s ease;
}

.booking-cancel-cancel-button:hover {
    background-color: #4189b6;
    transform: scale(1.05);
}

/* Fade In Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.95);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}
