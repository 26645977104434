
.daycare-remaining-container {
    position: absolute;
    top: 10px !important;
    right: 38px !important;
}

.daycare-remaining-icon {
    position: absolute !important;
    /* top: 7px;
    right: 7px; */
    width: 30px !important;
    height: 30px !important;
}

.daycare-remaining-count-less {
    position: absolute;
    color: red;
    font-size: 10px;
    font-weight: 600;
    padding: 6px 12px;
    /* border-radius: 50%; */
}

.daycare-remaining-count {
    position: absolute;
    color: #007BFF;
    font-size: 10px;
    font-weight: 600;
    padding: 6px 9px;
    /* border-radius: 50%; */
}