.add-profile-access-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 8888;
}

.add-profile-modal {
    width: 500px;
}

.add-profile-modal.show {
    display: block;
    top: 15%;
    padding: 10px;
}

.add-pet-modal-title {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
}

.add-pet-modal-message {
    margin-top: -15px;
    margin-bottom: 30px;
}

.add-profile-modal, add-profile-modal label {
    text-align: center;
    font-size: 13px;
}

.add-profile-modal-header .add-profile-add-pet-close-btn {
    border: none;
    background-color: transparent;
    font-size: 1.5rem;
    cursor: pointer;
}

.modal-body .form-group {
    margin-bottom: 1rem;
}

.btn-close-red {
    background-color: rgb(199, 0, 0);
    color: white;
    border: none;
}

.btn-close-red:hover {
    background-color: darkred;
}

.add-profile-family-member-section {
    /* min-height: 250px; */
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .add-profile-family-member {
    flex-basis: 25%;
    text-align: center;
  }
  
  .add-profile-family-title-image {
    width: 100%;
  }
  
  .add-profile-family-member-image {
    width: 80px;
    height: 80px;
    padding: 3px;
    margin: 7px;
    object-fit: cover;
    border-radius: 50%;
    /* border: 1px solid #A66D4F; */
  }

  .input-field, input-field label {
    text-align: center;
    font-size: 13px;
}

/* Increase specificity */
.add-profile-input-button-container input {
    width: 55%; /* Adjust input width as needed */
    margin-right: 10px; /* Adds space between input and button */
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 15px;
}

.input-field input {
    padding: 10px;
    margin: 3px;
    border: 2px solid #ccc;
    border-radius: 15px;
}

.option-group h2 {
    font-size: 15px;
    margin-left: 15px;
    margin-bottom: 10px;
    font-weight: 600;
}

.option-group-header {
    margin-top: 20px;
    margin-bottom: 10px;
}

/* Container to align the input and button next to each other */
.add-profile-input-button-container {
    display: flex !important;
    align-items: center !important;
}

.add-profile-input-button-container input {
    margin-right: 5px !important;
}

/* Adjust input field inside the flex container */
/* .add-profile-input-button-container input {
    flex: 1 !important;
    margin-right: 10px !important;
} */

/* Ensure the button has its regular size */
.add-profile-input-button-container button {
    padding: 10px 15px !important;
    margin: 5px;
    margin-right: 10px;
    border: none;
    border-radius: 15px;
    font-size: 13px;
    background-color: #FFB516;
    color: white;
    /* font-weight: 500; */
    cursor: pointer;
    transition: background-color 0.2s ease, transform 0.2s ease;
    padding: 10px 20px;
}

/* Scope the styles to the AddProfileModal component */
.add-profile-modal .add-profile-input-field input, 
.add-profile-modal .add-profile-input-field select {
    padding: 10px;
    margin: 5px auto;
    border: 2px solid #ccc;
    border-radius: 15px;
    font-size: 13px;
    width: 55%;
    text-align: center;
    text-align-last: center; /* Centers the dropdown text */
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

/* Highlight on focus for inputs/selects in AddProfileModal */
.add-profile-modal .add-profile-input-field input:focus, 
.add-profile-modal .add-profile-input-field select:focus {
    border-color: #FFB516;
    box-shadow: 0 0 10px rgba(255, 181, 22, 0.5);
    outline: none;
}

/* Button styles */
.add-profile-modal .btn {
    margin: 5px;
    border: none;
    border-radius: 15px;
    font-size: 13px;
    color: white;
    cursor: pointer;
    transition: background-color 0.2s ease, transform 0.2s ease;
    padding: 10px 20px;
}

.add-profile-modal .btn:hover {
    background-color: #FFB516;
    transform: scale(1.02);
    color: white;
}

.add-profile-modal .btn-close-red {
    background-color: rgb(199, 0, 0);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 15px;
}

.add-profile-modal .btn-close-red:hover {
    background-color: rgb(199, 0, 0);
    transform: scale(1.02);
}

.add-profile-modal .btn-close-blue {
    background-color: #4CA7DF;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 15px;
}

.add-profile-modal .btn-close-blue:hover {
    background-color: #4189b6;
    transform: scale(1.02);
}

/* Selected input field styles */
.selected-size-button, .selected-package-button {
    color: #ffffff !important;
    transform: scale(1.02) !important;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6) !important;
    background-color: #FFB516 !important;
}

.add-profile-family-member {
    text-align: center;
    margin: 10px;
    cursor: pointer;
}

.add-profile-family-member-image:hover {
    transform: scale(1.05);
    box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.3);
}

.add-profile-family-member-name {
    margin-top: 10px;
    font-size: 1rem;
}

.add-profile-family-member-type {
    color: #555;
}

.add-profile-family-member-name {
    margin-top: -10px;
    margin-left: 60px;
    font-size: 12px;
    color: rgb(75, 75, 75);
  }
  
  .add-profile-family-member-type {
    margin-top: -10px;
    margin-left: 60px;
    font-size: 10px;
    font-weight: 400;
    color: #A66D4F;
  }

@media (max-width: 400px) {
    .add-profile-family-member-section {
      margin-top: 20px;
    }
  
    .add-profile-family-member-image {
      width: 70px;
      height: 70px;
    }

    .add-profile-modal .add-profile-input-field input, 
    .add-profile-modal .add-profile-input-field select
     {
        padding: 5px;
        margin: 5px auto;
        border: 2px solid #ccc;
        border-radius: 15px;
        font-size: 13px;
        width: 55%;
        text-align: center;
        transition: border-color 0.3s ease, box-shadow 0.3s ease;
    }

    .add-pet-modal-title {
        font-size: 17px;
    }
    
    .add-pet-modal-message {
        font-size: 12px;
    }

    .add-profile-modal .add-profile-input-field-two input, 
    .add-profile-modal .add-profile-input-field-two select {
        padding: 5px !important;
    }
  }
  
  @media (max-width: 420px) {
    .add-profile-family-member-section {
      margin-top: 20px;
    }
  
    .add-profile-family-member-image {
      width: 70px;
      height: 70px;
    }

    .add-profile-family-member {
        margin: 3px !important;
    }
  }

.add-pet-profile-image {
    object-fit: cover;
    display: block; /* Remove inline default */
    margin-top: -10px !important;
    max-width: 80px; /* Set a max-width for your image */
    height: auto; /* Maintain aspect ratio */
    border-radius: 50%; /* Make it round */
    margin: 10px;
}

.add-pet-image-upload-label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7); /* Slight white overlay */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0; /* Hide by default */
}

.add-profile-modal .add-profile-input-field-two input, 
.add-profile-modal .add-profile-input-field-two select {
    padding: 10px;
    margin: 5px auto;
    border: 2px solid #ccc;
    border-radius: 15px;
    font-size: 13px;
    width: 75%;
    text-align: center;
    text-align-last: center; /* Centers the dropdown text */
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

/* Highlight on focus for inputs/selects in AddProfileModal */
.add-profile-modal .add-profile-input-field-two input:focus, 
.add-profile-modal .add-profile-input-field-two select:focus {
    border-color: #FFB516;
    box-shadow: 0 0 10px rgba(255, 181, 22, 0.5);
    outline: none;
}

.add-pet-record-message {
    font-size: 11px;
}

.add-pet-error-message {
    justify-content:center !important;
    color: rgb(199, 0, 0);
}

.form-control.input-error {
    border: 2px solid rgb(199, 0, 0) !important;
}

.add-profile-family-member-image.input-error {
    background-color: rgb(199, 0, 0);
}

.add-pet-modal-body {
    text-align: center;
}

.add-profile-modal.show {
    max-height: 80vh;
    overflow-y: auto; /* Enables vertical scrolling */
}

.modal-body {
    max-height: 80vh; /* Ensures body remains scrollable */
    overflow-y: auto;
}

input[type="file"] {
    text-align-last: unset !important;
}