.membership-edit-header {
    color: #4A870C;
    font-size: 20px;
    font-weight: 600;
}

.membership-edit-member-info {
    flex-grow: 1;
    display: flex;
    /* align-items: center; */
}

.membership-edit-image {
    object-fit: cover;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    margin-right: 10px;
    margin-left: 15px;
}

.membership-edit-section label {
    font-size: 14px;
}

.membership-edit-benefits {
    font-size: 14px;
}

.membership-edit-benefits li, p {
    font-size: 14px;
    list-style: disc;
}

.membership-edit-name {
    font-size: 14px;
    font-weight: 500;
    margin-top: 20px;
}

.membership-edit-type {
    margin-top: -10px;
    font-size: 11px;
    font-weight: 400;
    color: #A66D4F;
}

.membership-edit-section {
    margin-bottom: 30px;
}

.membership-edit-form {
    padding: 15px;
}

.membership-edit-form label {
    margin-bottom: 10px;
}

.membership-edit-form select {
    margin-left: 10px;
}

.membership-edit-information {
    padding: 15px;
    border-radius: 25px;
}

.membership-edit-information p {
    margin-left: 10px;
}

.membership-edit-details {
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.membership-edit-details-container {
    margin-top: 50px;
    border-radius: 25px;
}

.membership-edit-details-container h2 {
    color: #4A870C;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
}

.membership-edit-details-container p {
}

.membership-edit-details-container select {
    width: 80%;
    margin-top: 5px;
    margin-bottom: 5px;
}

.membership-edit-buttons-container {
    display: flex;
    justify-content: center;
}

.membership-edit-buttons-container button {
    margin: 5px;
    margin-top: 10px;
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 14px;
    color: white;
    width: 100px;
    height: 35px;
    border-radius: 10px;
    border: none;
}

.membership-edit-update-button {
    background-color: #47B2E7;
}

.membership-edit-contact-support-button {
    background-color: #FFB516;
}

.membership-edit-cancel-button {
    background-color: #E9716B;
}

.membership-edit-form select {
    margin-left: 10px;
    padding: 5px;
    font-size: 14px;
    color: #333;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 25px;
    outline: none;
    transition: border-color 0.3s ease;
}

.membership-edit-form select:focus {
    border-color: #FFB516;
}

/* Header of the popup */
.membership-edit-popup-header {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

.membership-edit-popup-message {
    font-size: 13px;
    text-align: center;
    /* color: rgb(199, 0, 0); */
}

.membership-edit-popup-button-container {
    display: flex;
    justify-content: space-between; /* This will put the buttons on either end */
    padding: 10px; /* Add some padding around the buttons */
}
  
.membership-edit-popup-delete {
    padding: 5px 10px;
    border-radius: 5px;
    background-color: rgb(199, 0, 0);
    border: none;
    color: white;
    font-size: 13px;
    font-weight: 600;
}
  
.membership-edit-popup-cancel {
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #FFB516;
    border: none;
    color: white;
    font-size: 13px;
    font-weight: 600;
}