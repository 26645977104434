.navbar {
  height: 5rem;
  display: flex;
  align-items: center;
  border-bottom: 2px solid rgba(51, 51, 51, 0.1); /* Use rgba with an alpha value for opacity */
  background-color: white;
  z-index: 9999;
}

.container {
  /* display: flex; */
  align-items: center;
}

.navbar-brand {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.navLogo {
  width: 250px;
  height: auto;
  margin-left: 5px;
}

.navLogoSmall {
  display: none;
}

@media (max-width: 768px) {
  .navLogo {
      display: none;
  }
  .navLogoSmall {
      display: block;
      width: 40% !important;
  }
}

/* New wrapper to group Login and Dropdown buttons */
.navbar-buttons {
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust spacing between Login and Dropdown buttons */
  margin-left: auto; /* Pushes buttons to the right side */
}

.navbar-toggle {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: auto;
  padding: 0;
}

.navbar-login-button {
  font-weight: bold;
  font-size: 14px;
  background-color: #47B2E7;
  color: white;
  width: 80px;
  height: 35px;
  margin-left: 200px;
  border-radius: 10px;
  border: none;
  /* flex-grow: 1; */
}

.dropdown-icon {
  width: 50%;
}

.dropdown-menu {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f8f9fa;
  padding: 10px;
  z-index: 999;
  overflow: hidden;
  animation: dropdownOpen 0.5s forwards ease-in-out;
}

@keyframes dropdownOpen {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.dropdown-link {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

@media (max-width: 768px) {
  .navbar-menu {
    display: none;
  }

  .navbar-menu.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #f8f9fa;
    padding: 10px;
    z-index: 999;
  }
}

.dropdown-top {
  display: flex;
  justify-content: space-between;
}

.dropdown-left {
  flex: 1;
}

.dropdown-right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.navbar-brand {
  width: 100px !important;
  position: absolute;
  left: 0;
  margin-left: 20px;
}

.close-button {
  background: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 0;
  margin-right: 10px;
}

.close-button-image {
  width: 80%;
}

.dropdown-items {
  margin-top: 80px;
  text-align: center;
}

.dropdown-item {
  /* margin: 10px 0; */
  color: #18181897;
  font-size: 14px;
}

.dropdown-title {
  color: #FFB516;
  margin-top: 20px;
  font-weight: 600;
  font-size: 18px;
}

/* Tablet view */
@media screen and (min-width: 768px) {
  /* Styles for tablet */
}

/* Desktop view */
@media screen and (min-width: 1024px) {
  /* Styles for desktop */
}