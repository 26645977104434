.career-background-one-container {
    background-image: url('../../../assets/Store/Services/pageBackground.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: right top;
}

@media (max-width: 767px) {
    .career-background-one-container {
        background-image: url('../../../assets/Store/Services/pageBackgroundMobile.png');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: right top;
    }
}

.career-container {
    margin: 20px;
    margin-top: 100px;
}

.career-page-icon {
    width: 35px;
    margin-bottom: 15px;
}

.career-page-heading {
    font-size: 37px;
    font-weight: 400;
    color:rgba(0, 0, 0, 0.719);
    text-align: left;
}

.career-page-paragraph {
    font-size: 16px;
    color:rgba(0, 0, 0, 0.719);
    text-align: left;
    margin-bottom: 30px;
}

.centered-container {
    display: flex;
    justify-content: center;
}

.career-page-button {
    font-weight: bold;
    font-size: 14px;
    background-color: #47B2E7;
    color: white;
    width: 200px;
    height: 45px;
    border-radius: 18px;
    border: none;
    margin: 20px;
}

.career-page-main-photo {
    width: 100%;
}

.career-background-two-container {
    background-image: url('../../../assets/Store/Services/pageSubBackground.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: right top;
}

@media (max-width: 767px) {
    .career-background-two-container {
        background-image: url('../../../assets/Store/Services/pageSubBackgroundMobile-3.png');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: right top;
    }
}

.opportunities-heading {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    color:rgba(0, 0, 0, 0.824);
}

.opportunity-paragraph {
    font-size: 15px;
    margin-top: 40px;
    margin-bottom: 100px;
    text-align: center;
}

.career-subpage-heading {
    margin-top: 40px;
    font-size: 13px;
    font-weight: bold;
    color:rgba(0, 0, 0, 0.318);
    text-align: center;
}

.career-subpage-heading-3 {
    margin-top: 50px;
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 300;
    color:rgba(0, 0, 0, 0.719);
    text-align: center;
}

.career-services-item {
    display: flex;
    margin: 10px;
    margin-bottom: 20px;
    align-items: flex-start; /* Align items at the top */
}
  
.career-services-item-icon {
    width: 65px;
    margin-left: -35px !important;
    margin-right: 15px;
}

.career-services-item-heading {
    font-size: 20px;
    color:rgba(0, 0, 0, 0.824);
}

.career-services-item-paragraph {
    font-size: 14px;
    color:rgba(0, 0, 0, 0.824);
}

.job-listings {
    margin-left: -30px;
}

.job-item {
    border-radius: 20px;
    padding: 12px 22px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    margin: 15px 10px;
}

.job-title {
    font-size: 20px;
}

.job-details-p {
    margin-bottom: 0px !important;
}

.job-details p {
    margin-bottom: 1rem; /* Adds a gap below each paragraph */
  }
  
  .job-details ul {
    margin-bottom: 1.5rem;
  }

.job-details li {
    font-size: 14px;
    list-style-type: disc;
}

.mission-container {
    /* margin: 20px;
    margin-top: 120px; */
    padding: 30px;
}

.career-mission-header {
    margin-top: 50px;
    margin-bottom: 30px;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.719)
}

.career-mission-paragraph {
    font-size: 16px;
    text-align: center;
    color: rgba(0, 0, 0, 0.719);
}

.career-safety-container {
    padding: 20px;
}

.career-list {
    margin-left: -30px;
}

.location-item {
    border-radius: 20px;
    padding: 10px 15px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    margin: 15px 10px;
}

.location-item-name {
    font-size: 22px;
    font-weight: 500;
}

.support-icon {
    margin-top: -4px;
}

.location-item-address-two {
    margin-left: 33px;
    margin-top: -10px;
}

.career-list-heading {
    margin-top: 80px;
    margin-bottom: 40px;
    font-size: 15px;
    font-weight: bold;
    color:rgba(0, 0, 0, 0.318);
    text-align: center;
}

.career-list-heading-3 {
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 18px;
    font-weight: 300;
    color:rgba(0, 0, 0, 0.719);
    text-align: center;
}

.career-background-four-container {
    background-image: url('../../../assets/Store/Services/pageSubBackground.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
}

@media (max-width: 767px) {
    .career-background-four-container {
        background-image: url('../../../assets/Store/Services/pageSubBackgroundMobile-3.png');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center center;
    }
}

.partner-container, .join-us-container, .career-opportunity-container {
    padding: 30px;
}

.partner-heading, .join-us-heading, .career-heading {
    font-size: 23px;
    color:rgba(0, 0, 0, 0.824);
}

.partner-message, .join-us-message, .career-message {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.719);
}

.career-partner-button {
    font-size: 14px;
    font-weight: 500;
    color: #4CA7DF;
    background: none;
    border: none;
    cursor: pointer;
    margin-left: -5px;
}

.career-join-us-button {
    font-size: 14px;
    font-weight: 500;
    color: #4CA7DF;
    background: none;
    border: none;
    cursor: pointer;
    margin-left: -5px;
}

.career-career-button {
    font-size: 14px;
    font-weight: 500;
    color: #4CA7DF;
    background: none;
    border: none;
    cursor: pointer;
    margin-left: -5px;
}


/* Responsive CSS */
@media (min-width: 400px) {
    .career-container {
        margin-top: 120px;
    }

    .career-page-heading {
        font-size: 38px;
        font-weight: 300;
    }
    
    .career-page-paragraph {
        font-size: 16px;
    }

    .career-subpage-heading {
        font-size: 15px;
    }
    
    .career-subpage-heading-3 {
        font-size: 23px;
        margin-bottom: 40px;
    }
    
    .career-services-item-heading {
        font-size: 22px;
    }
    
    .career-services-item-paragraph {
        font-size: 16px;
        margin-bottom: 20px;
    }
    
    .career-safety-heading {
        font-size: 15px;
    }
    
    .career-safety-heading-3 {
        font-size: 23px;
        margin-bottom: 40px;
    }
}

.job-item-buttons {
    text-align: right;
}

.learn-more-button {
    background-color: #4CA7DF;
    font-size: 14px;
    font-weight: 500;
    color: white;
    border: none;
    padding: 5px 10px;
    margin-left: 10px; /* Space between the buttons */
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.apply-now-button {
    background-color: #E9716B;
    font-size: 14px;
    font-weight: 500;
    color: white;
    border: none;
    padding: 5px 10px;
    margin-left: 10px; /* Space between the buttons */
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.learn-more-button:hover,
.apply-now-button:hover {
    background-color: #488;
}