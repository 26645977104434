/* ButtonUtils.css */

.back-button,
.delete-button,
.black-delete-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 10px;
  font-weight: 600;
  margin: 5px;
}

.white-back-button {
  border: none;
  background-color: transparent;
  color: white;
  cursor: pointer;
  font-weight: 600;
  margin-top: 20px;
  margin-left: 20px;
  font-size: 13px;
}

.x-button {
  position: absolute;
  color: white;
  border: none;
  background-color: transparent;
  cursor: pointer;
  /* padding: 10px; */
  font-weight: 600;
  margin: 10px;
  margin-top: 15px;
}

.view-button {
  color: white;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-weight: 600;
}

.cart-view-button {
  border: none;
  margin-right: 5px;
  background-color: transparent;
  cursor: pointer;
  color: #4CA7DF;
  font-size: 12px;
}

.payment-history-view-button {
  border: none;
  margin-right: 5px;
  background-color: transparent;
  cursor: pointer;
  color: #4CA7DF;
  font-size: 12px;
  font-weight: 600;
}

/* Style specifically for back button */
.back-button {
  color: #404040;
  font-size: 13px;
}

/* Style specifically for delete button */
.delete-button {
  color: rgb(199, 0, 0);;
  font-size: 13px;
}

.black-delete-button {
  counter-reset: black;
  font-size: 13px;
}

.x-button {
  color: white;
  font-size: 15px;
  font-weight: bold;
}

.x-button-image {
  width: 30px;
  height: 25px;
}

.view-button {
  color: #4CA7DF;
  font-size: 12px;
  /* font-weight: bold; */
}

.view-button-image {
  width: 17px;
  height: 17px;
  margin-bottom: 2px;
}

.cart-view-button-image {
  width: 12px;
  height: 12px;
}
