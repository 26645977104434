.footer-section {
    margin: 10px;
    margin-top: 20px !important;
    font-size: 15px;
    font-family: 'Roboto' !important;
}

.footer-logo {
    max-width: 200px;
}

.footer-header {
    font-size: 18px;
}

.footer-section li {
    color:rgba(0, 0, 0, 0.719);
    font-size: 13px;
    margin-top: 1px;
}

.footer-section a {
    color: rgba(0, 0, 0, 0.719);
    text-decoration: none;
}

.footer-links a {
    color: #4CA7DF;
}
.footer-section ul {
    list-style-type: none;
}

.footer-social {
    margin-top: 20px;
}

.footer-social-icon {
    width: 35px;
    margin-right: 15px;
}

.footer-navigation {
    margin-top: 30px;
    margin-left: -30px !important;
}

.footer-services {
    margin-top: 30px;
    margin-left: -30px !important;
}

.footer-faqs {
    margin-top: 30px;
    margin-left: -30px !important;
}

.footer-links {
    font-size: 14px;
    margin-top: 30px;
}

.footer-copyright {
    font-size: 14px;
    margin-bottom: 20px;
}

@media (min-width: 400px) {
    .footer-section {
        margin: 10px;
        margin-top: 20px !important;
        font-size: 16px;
    }
    
    .footer-logo {
        max-width: 220px;
    }
    
    .footer-header {
        font-size: 20px;
    }
    
    .footer-section li {
        font-size: 14px;
    }
    
    .footer-social-icon {
        width: 35px;
        margin-right: 15px;
    }
    
    .footer-links {
        font-size: 14px;
    }
    
    .footer-copyright {
        font-size: 14px;
    }
}

@media screen and (min-width: 668px) {
    .footer-section {
        margin: 30px !important;
        margin-top: 20px !important;
    }

    .footer-logo {
        max-width: 250px;
    }

    .footer-header {
        font-size: 25px;
    }
    
    .footer-section li {
        font-size: 18px;
    }
    
    .footer-social-icon {
        width: 50px;
        margin-right: 18px;
    }
    
    .footer-links {
        font-size: 18px;
    }
    
    .footer-copyright {
        font-size: 18px;
    }
}

@media screen and (min-width: 1024px) {
    .footer-section {
        margin: 200px !important;
        margin-top: 20px !important;
    }

    .footer-logo {
        max-width: 300px;
    }

    .footer-header {
        font-size: 30px;
    }
    
    .footer-section li {
        font-size: 20px;
    }
    
    .footer-social-icon {
        width: 50px;
        margin-right: 20px;
    }
    
    .footer-links {
        font-size: 20px;
    }
    
    .footer-copyright {
        font-size: 20px;
    }
}

@media screen and (min-width: 1424px) {
    .footer-section {
        margin-left: 230px !important;
    }

    .footer-section li {
        font-size: 18px;
    }
    
    .footer-social-icon {
        width: 50px;
        margin-right: 18px;
    }
    
    .footer-links {
        font-size: 18px;
    }
    
    .footer-copyright {
        font-size: 18px;
    }
}