/* Disables dragging for all images within the carousel */
.landing .carousel img {
  -webkit-user-drag: none;
  user-select: none;
  pointer-events: none;
}


.hero-section {
    position: relative;
  }
  
  .hero-slide {
    position: relative;
    height: 400px;
  }
  
  .hero-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .hero-content {
    /* ...existing styles... */
  }

  .menu-section {
    margin-top: -1px;
  }

  .menu-container {
    position: absolute;
    top: 800px;
    z-index: 2;
  }

  @media (min-width: 300px) and (max-width: 320px) {
      .menu-container {
          top: 660px;
      }
  }

  @media (min-width: 321px) and (max-width: 340px) {
      .menu-container {
        top: 690px;
      }
  }

  @media (min-width: 341px) and (max-width: 360px) {
      .menu-container {
        top: 720px;
      }
  }

  @media (min-width: 361px) and (max-width: 380px) {
      .menu-container {
        top: 750px;
      }
  }

  @media (min-width: 381px) and (max-width: 400px) {
      .menu-container {
        top: 780px;
      }
  }

  @media (min-width: 401px) and (max-width: 420px) {
      .menu-container {
        top: 810px;
      }
  }

  @media (min-width: 421px) and (max-width: 450px) {
      .menu-container {
        top: 840px;
      }
  }

  .menu-carousel {
    
  }

  .menu-background {
  }
  
  .menu-button-container {
    
  }

  .button-image-container {
    background-color: white;
    text-align: center;
    margin: 5px;
    border-radius: 10px;
  }

  .menu-button-image {
    width: 50%;
    padding: 3px;
    margin: 5px;
  }

  .menu-button-title {
    text-align: center;
    font-size: 12px;
    color: white;
  }

  .activities-section {
    margin-top: 20px;
    position: relative;
    overflow: hidden;
  }

  .activities-carousel-container {
    position: absolute;
    top: 45%;
    left: 0;
    right: 0;
    bottom: 10%;
    z-index: 1;
  }

  .activities-image {
    max-width: 140px;
    max-height: 120px;
    border-radius: 35px;
  }


.why-us-section {
  /* background-image: url('../../assets/Store/Services/pageSubBackground.png'); */
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: right top;
}

@media (max-width: 767px) {
  .why-us-section {
      /* background-image: url('../../assets/Store/Services/pageSubBackgroundMobile-1.png'); */
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: right top;
      min-height: 900px;
  }
}

.landing-subpage-heading {
  margin-top: 40px;
  margin-bottom: 30px;
  font-size: 13px;
  font-weight: bold;
  color:rgba(0, 0, 0, 0.318);
  text-align: center;
}

/* .landing-subpage-heading-3 {
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 300;
  color:rgba(0, 0, 0, 0.719);
  text-align: center;
} */

.landing-services-item {
  display: flex;
  margin: 10px;
  margin-bottom: 20px;
  align-items: flex-start;
}

.landing-services-item-details {
  margin-left: -35px !important;
}

.landing-services-item-icon1, .landing-services-item-icon3 {
  width: 150px;
  margin-bottom: 15px;
  margin-right: -15px !important;
}

.landing-services-item-icon2, .landing-services-item-icon4 {
  width: 150px;
  margin-left: -45px !important;
  margin-right: 15px;
}

.landing-services-item-heading1, .landing-services-item-heading3  {
  font-size: 20px;
  margin-left: -20px !important;
  margin-top: 25px;
  margin-bottom: -25px;
  max-width: 170px;
  color:rgba(0, 0, 0, 0.824);
}

.landing-services-item-heading2, .landing-services-item-heading4  {
  font-size: 20px;
  max-width: 170px;
  margin-left: 25px !important;
  color:rgba(0, 0, 0, 0.824);
}

.landing-services-item-paragraph {
  font-size: 14px;
  margin-top: 10px;
  margin-right: -10px;
  color:rgba(0, 0, 0, 0.824);
  flex-grow: 1;
}

@media (min-width: 400px) {
  .landing-services-item-paragraph {
    font-size: 16px;
  }
}

.landing-services-item-details {
  display: flex;
  align-items: center; /* Align items vertically in the container */
}

.sign-up-section {
  background-image: url('../../assets/Landing/landingPageSubBackground.png');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

@media (max-width: 767px) {
  .sign-up-section {
      background-image: url('../../assets/Landing/landingPageSubBackgroundMobile-3.png');
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center center;
  }
}