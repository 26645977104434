.dashboard {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 2px 0;
    background-image: url('../../assets/Dashboard/dashboardFemale.png');
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 8888;
    pointer-events: none; /* Allows clicks to pass through */
}  

.dashboard-container {
    padding: 0px !important;
}
  
.dashboard-menu {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    margin-top: 80px;
    pointer-events: auto; /* Enables clicks for menu items */
}

.dashboard-menu nav {

}
  
.dashboard-menu .nav-link {
    margin: 0 6px;
    margin-bottom: -10px;
    color: #555;
    text-decoration: none;
    color: white;
}
  
.dashboard-menu .nav-link:hover {
    color: #333;
}

.dashboard-icon {
    width: 42px;
    height: 40px;
}

.dashboard-title {
    font-size: 11px;
    text-align: center;
}

.cart-icon-link .cart-icon-container {
    position: relative;
    display: inline-block; /* Ensures the badge positions correctly relative to the icon */
  }
  
  .cart-item-count {
    position: absolute;
    top: 2px;
    right: 2px;
    background-color: #d60000; /* Or any color that fits your design */
    color: white;
    font-size: 10px;
    padding: 2px 6px;
    border-radius: 50%;
  }
  

  /* Tablet view */
@media screen and (min-width: 668px) {
    .dashboard {
        position: fixed;
        left: 0;
        width: 100%;
        padding: 2px 0;
        background-image: url('../../assets/Dashboard/dashboardFemaleTablet.png'); /* Add specific styles for desktop */
    }

    .dashboard-menu {
        display: flex;
        justify-content: right;
        margin-right: 25px;
    }

    .dashboard-icon {
        width: 45px;
        height: 42px;
        margin-top: -10px;
    }

    .dashboard-title {
        font-size: 14px;
    }
}

@media screen and (min-width: 1024px) {
    .dashboard {
        position: fixed;
        left: 0;
        width: 100%;
        padding: 15px 0;
        background-image: url('../../assets/Dashboard/dashboardFemaleDesktop.png'); /* Add specific styles for desktop */
    }

    .dashboard-menu {
        display: flex;
        justify-content: right;
        margin-right: 70px;
        margin-bottom: -20px;
    }

    .dashboard-icon {
        width: 45px;
        height: 42px;
    }

    .dashboard-title {
        font-size: 14px;
    }
}

@media screen and (min-width: 1424px) {
    .dashboard {
        position: fixed;
        left: 0;
        width: 100%;
        padding: 15px 0;
        background-image: url('../../assets/Dashboard/dashboardFemaleDesktop.png'); /* Add specific styles for desktop */
    }

    .dashboard-menu {
        display: flex;
        justify-content: right;
        margin-right: 170px;
    }

    .dashboard-icon {
        width: 45px;
        height: 40px;
        margin-top: 4px;
        margin-left: 3px;
    }

    .dashboard-title {
        font-size: 16px;
    }
}

@media screen and (min-width: 1554px) {
    .dashboard-title {
        font-size: 17px;
    }
}