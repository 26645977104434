.console-header {
    /* color: #4CA7DF; */
    margin-top: 20px;
    text-align: center;
    font-size: 25px;
}

.console-sections {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 20px;
}

.console-section {
    background-color: #FFF;
    border: 2px solid #DDD;
    border-radius: 8px;
    padding: 10px;
    margin: 10px;
    flex-basis: calc(50% - 20px);
    text-align: center;
    transition: all 0.3s ease;
}

.console-section:hover {
    border-color: #FFB516;
    cursor: pointer;
    transform: scale(1.02);
}

.console-section:nth-child(1) {
    border-color: #FFB516;
}

.console-section:nth-child(2) {
    border-color: #4CA7DF;
}

.console-section:nth-child(3) {
    border-color: #E9716B;
}

.console-section:nth-child(4) {
    border-color: #A17FD4;
}

.console-section:nth-child(5) {
    border-color: #0CBC51;
}

.console-section:nth-child(6) {
    border-color: black;
}
