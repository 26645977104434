.staff-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}

.staff-card {
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    align-items: center;
    width: 95%;
    transition: all 0.3s ease;
}

.staff-card:hover {
    border-color: #007bff; /* Light blue hover effect */
    box-shadow: 0 2px 5px rgba(0, 123, 255, 0.2); /* Slight shadow for depth */
}

.staff-card img {
    margin-right: 15px;
    width: 50px; 
    height: 50px; 
    border-radius: 50%;
}

.staff-info h3 {
    margin: 0;
    margin-top: 10px;
    font-size: 15px;
}

.staff-info p {
    /* margin: 5px 0; */
    font-size: 14px;
}

.staff-role-job {
    display: flex;
    justify-content: space-between; /* Adjust this to control the spacing between role and job */
    width: 100%; /* Ensures the container fills the card */
}

.staff-role-job p {
    margin-right: 10px;
}
