.pet-selection-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* grid-gap: 10px;
    gap: 10px; */
}

.pet-selection-options button {
    width: 90%;
    height: 90%;
}

.pet-name {
    font-size: 13px;
}

.options-container button {
    margin: 3px;
    border: none;
    border-radius: 15px;
    font-size: 13px;
    color: black;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

/* Selected size button style */
.selected-size-button {
    color: #ffffff !important;
    transform: scale(1.02) !important;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6) !important;
    background-color: #FFB516 !important;
  }
  
  /* Selected package button style */
.selected-package-button {
    color: #ffffff !important;
    transform: scale(1.02) !important;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6) !important;
    background-color: #FFB516 !important;
}

.options-container button.selected {
    color: #ffffff !important;
    transform: scale(1.02) !important;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6) !important;
    background-color: #FFB516 !important;
}

.input-field, input-field label {
    text-align: center;
    font-size: 13px;
}

.input-field input {
    padding: 10px;
    margin: 3px;
    border: 2px solid #ccc;
    border-radius: 15px;
}

.option-group h2 {
    font-size: 15px;
    margin-left: 15px;
    margin-bottom: 10px;
    font-weight: 600;
}

.option-group-header {
    margin-top: 20px;
    margin-bottom: 10px;
}

.option-group-button {
    text-align: center;
    margin: 30px;
}

.swap-icon {
    width: 35px;
    object-fit: contain;
}

.service-icon {
    width: 30px;
    margin: 5px;
}

.service-selection {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.button-group {
    text-align: center;
}

.shuttle-service-button-, .shuttle-service-button-selected {
    min-width: 100px;
    display: flex;
    font-size: 13px !important;
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
}

.shuttle-service-button- {
    color: black;
}

.shuttle-service-button-selected {
    border: 2px solid #FFB516; /* Apply theme color to the selected card */
    transform: scale(1.02); 
    transition: transform 0.3s ease, margin 0.3s ease, box-shadow 0.3s ease; 
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4); 
    background-color: #FFB516; 
    color: white;
}

.pet-button {
    color: black;
}

.shuttle-booking-container {
    display: flex;
    justify-content: center;

    gap: 10px; /* Adjust spacing as needed */
}