.mobile-access-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8); /* Dark background with transparency */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it's on top of everything */
  }
  
  .mobile-access-content {
    color: white;
    text-align: center;
    padding: 20px;
    background-color: rgba(255, 181, 22, 0.6); /* Slight background with your desired color */
    border-radius: 10px;
  }
  
  .mobile-access-content h2 {
    font-size: 25px;
    margin-bottom: 10px;
  }
  
  .mobile-access-content p {
    font-size: 18px;
  }
  