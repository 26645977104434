/* CSS for selected pet size buttons */
.selected-training-type-button {
    background-color: #FFB516; /* Define the selected background color */
    color: white; /* Define the text color for selected buttons */
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
}

/* CSS for selected grooming package buttons */
.selected-session-type-button {
    background-color: #FFB516; /* Define the selected background color */
    color: white; /* Define the text color for selected buttons */
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
}  

.training-list-style li {
    list-style: square;
}