/* Adventure Card Styles */
.adventure-card-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two cards per row */
    gap: 20px; /* Adjust the gap between cards as needed */
  }
  
  .adventure-card {
    position: relative; /* Make sure the card is a positioned container */
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 300px;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .adventure-card.selected {
    border: 2px solid #FFB516; /* Apply theme color to the selected card */
    transform: scale(1.02); 
    transition: transform 0.3s ease, margin 0.3s ease, box-shadow 0.3s ease; 
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4); 
    background-color: #FFB516; 
  }
  
  /* Rest of your CSS styles for adventure cards */
  
  
  .adventure-image {
    max-width: 100%;
    height: auto;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .adventure-info-section {
    margin: 10px;
  }
  
  .adventure-title {
    font-size: 0.8rem;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  
  .adventure-description {
    font-size: 0.65rem;
    color: #595959;
  }
  
  .adventure-date {
    position: absolute;
    top: 5px; /* Adjust the top position as needed */
    right: 5px; /* Adjust the left position as needed */
    background-color: rgba(0, 0, 0, 0.3); /* Add a semi-transparent background */
    color: white; /* Text color */
    padding: 5px 5px; /* Padding for the date */
    border-radius: 5px; /* Rounded corners for the date box */
    font-size: 0.6rem; /* Adjust the font size as needed */
    font-weight: bold; /* Make the date text bold */
  }

/* CSS for .overlay and .modal */
.booking-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .booking-modal {
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
    position: relative;
  }
  
  .booking-close-button {
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer;
    font-size: 24px;
  }
  
  .adventure-no-event-message {
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
    text-align: center;
    color: #E9716B;
  }