.wallet-backdrop {
    background-color: #4CA7DF;
}

.wallet-container {
    padding: 10px;
    /* background-color: #e6f7ff; */
    max-width: 400px;
    margin: 0 auto;
}

.wallet-card-container-one {
    align-items: center;
    background-color: white;
    border-radius: 20px;
    padding: 10px 15px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    margin: 5px 5px;
    margin-top: 50px;
    transition: background-color 0.3s;
}

.wallet-arrow-image {
    width: 13px;
    margin-left: 5px;
    margin-bottom: 1px;
}

.wallet-header {
    font-size: 18px;
    text-align: left;
    margin-left: 23px;
    font-weight: 600;
    color: white;
}

.wallet-card-header {
    font-size: 14px;
    font-weight: 600;
    color: #3f3f3f;
}

.wallet-card-paragraph {
    font-size: 11px;
    margin-top: -5px;
    /* font-weight: 600; */
    color: #3f3f3f;
}

.wallet-breakline {
    height: 3px;
    margin: 0 auto;
    color: inherit;
    border: 0;
    border-top: var(--bs-border-width) solid;
    opacity: 0.2;
}

.wallet-card-token-container {
    display: flex;
    justify-content: left;
    /* margin-bottom: 10px; */
}

.wallet-card-token-image {
    width: 50px;
    height: 47px;
    margin-top: 16px;
    margin-bottom: 15px;
}

.wallet-card-token-amount {
    font-size: 45px;
    font-weight: 600;
    margin-left: 10px;
    margin-top: 5px;
    color: #FFB516;
}

.wallet-card-history-paragraph {
    font-size: 11px;
    margin-top: 10px;
    /* font-weight: 600; */
    color: #3f3f3f;
}

.wallet-card-container-two {
    background-image: url('../../../../../assets//Dashboard/Profile/components/Wallet/containerTwoBackground.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
    align-items: center;
    background-color: white;
    border-radius: 20px;
    padding: 10px 15px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    margin: 5px 5px;
    margin-bottom: 20px;
    transition: background-color 0.3s;
}

.wallet-refer-header {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 600;
    color: #3f3f3f;
}

.wallet-refer-paragraph {
    font-size: 11px;
    margin-top: -5px;
    width: 50%;
    /* font-weight: 600; */
    color: #3f3f3f;
}

.wallet-refer-learn-paragraph {
    font-size: 12px;
    margin-top: 10px;
    font-weight: 600;
    color: #4CA7DF;
    margin-bottom: 20px;
}

.wallet-container-two {
    background-image: url('../../../../../assets/Dashboard/Profile/components/Wallet/containerTwoBackdrop.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
    margin-top: -1px;
    padding: 20px;
}

.wallet-gift-card-header {
    margin-top: 50px;
    font-size: 16px;
    font-weight: 600;
    color: #3f3f3f;
}

.wallet-gift-card-paragraph {
    font-size: 12px;
    margin-top: -5px;
    color: #3f3f3f;
}

.wallet-gift-card-button {
    font-size: 14px;
    font-weight: bold;
    background-color: #E9716B;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    margin: 0px -5px;
    width: 50%;
}

.wallet-gift-card-redeem {
    font-size: 12px;
    margin-top: 10px;
    font-weight: 600;
    color: #4CA7DF;
    margin-bottom: 10px;
    margin-left: 5px;
}

.wallet-breakline2 {
    height: 5px;
    margin: 0 auto;
    color: inherit;
    border: 0;
    border-top: var(--bs-border-width) solid;
    opacity: 0.2;
}

.wallet-container-three {
    padding: 20px;
}

.wallet-payment-header {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #3f3f3f;
}

.wallet-payment-paragraph {
    font-size: 12px;
    margin-top: -5px;
    color: #3f3f3f;
}

.wallet-card-display {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    border-radius: 8px;
    /* padding: 12px; */
    margin-top: 10px;
    margin-bottom: 10px;
    /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); */
  }

  .wallet-card-logo {
    width: 37px; /* Adjust size as needed */
  }
  
  .wallet-card-info {
    flex-grow: 1;
    padding-left: 12px; /* Adjust spacing as needed */
  }
  
  .wallet-card-number {
    font-size: 13px;
    /* font-weight: 600; */
    color: #4CA7DF;
  }
  
  .wallet-card-details {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    color: #666;
    margin-top: -3px;
  }
  
  .wallet-add-card-details {
    /* display: flex; */
    justify-content: left;
    font-size: 13px;
    color: #666;
    margin-top: -3px;
  }
  
  /* Add styles for .wallet-payment-button */
  .wallet-payment-button {
    display: flex;
    justify-content: center;
    align-items: center;
    /* ...other styles */
  }

  .wallet-cardholder-name {
    margin: auto;
  }
  
  .wallet-payment-breakline {
    height: 1px;
    margin: 0px -10px;
    color: inherit;
    border: 0;
    border-top: var(--bs-border-width) solid;
    opacity: 0.2;
  }

  .wallet-view-button-container {
    text-align: right;
  }