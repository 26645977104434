.store-container {
  /* max-width: 800px; */
  margin: 0 auto;
  margin-top: -5px;
  padding: 20px;
  /* background-color: rgb(245, 245, 245); */
  background-image: url('../../assets/Store/storeBackground.png');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top top;
}

.store-banner-section {
  margin-bottom: -1px;
}

.impact-banner-section {
  
}

.store-menu-container {
  /* background-color: rgb(210, 210, 210); */
  margin-top: 25px !important;
  margin-bottom: 55px !important;
}

@media screen and (min-width: 668px) {
  .store-container { 
    background-image: url('../../assets/Store/storeBackgroundTablet.png');
  }

  .store-menu-container {
    margin-top: 0px !important;
    margin-bottom: 40px !important;
    padding: 60px !important;
  }

  .store-button-image-container {
    background-color: white;
    text-align: center;
    margin: 3px auto !important;
    border-radius: 10px;
    width: 75%; /* Adjust this value as needed */
    height: 65%;
  }

  .store-menu-button-image {
    width: 35% !important;
    padding: 3px;
    margin: 5px;
  }

  .store-menu-button-title {
    text-align: center;
    font-size: 15px !important;
    font-weight: 500;
    color: white;
  }
}

.store-button-image-container {
  /* background-color: rgb(210, 210, 210); */
  background-color: white;
  text-align: center;
  margin: 5px;
  border-radius: 10px;
}

.store-menu-button-image {
  width: 50%;
  padding: 3px;
  margin: 5px;
}

.store-menu-button-title {
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: white;
}

.store-section {
  margin-bottom: 40px;
}

.deal-card-header {
  font-size: 20px;
  margin-bottom: 10px;
}

.deals-breakline {
  height: 5px;
  margin: 30px auto;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.2;
}

.book-button,
.shop-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
}

.shop-button {
  background-color: #28a745;
}

.deals-container h2 {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
}

.deal-card-container {
  display: flex;
  margin-top: 15px;
  justify-content:space-evenly;
  flex-wrap: wrap; /* In case there are more than two cards, this will allow them to wrap to the next row. */
}

.deal-card {
  flex: 0 0 48%;
  max-width: 75px;
  /* margin: 10px; */
  padding: 5px;
  /* border: 1px solid #e0e0e0; */
  border-radius: 20px;
  /* box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); */
  transition: 0.3s;
  text-align: center; /* optional: centers the text and button inside the card */
}

.deal-card:hover {
  transform: translateY(-5px);
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.15);
}

.deal-card img {
  max-width: 30px; /* Make sure image doesn't overflow the card */
  border-radius: 10px; /* Optional: Rounded corners for images */
  margin-bottom: 5px;
}

.deal-card h3 {
  font-size: 12px; /* Adjust as needed */
  font-weight: 500;
  /* margin-bottom: 10px; */
}

.deal-card p {
  font-size: 12px; /* Adjust as needed */
  /* margin-bottom: 20px; */
}

.deal-card button {
  font-size: 14px;
  background-color: #ff4500; /* Orange color, adjust as needed */
  color: #ffffff; /* White text */
  padding: 10px 20px;
  border: none;
  /* border-radius: 5px; Slight rounding of button corners */
  cursor: pointer; /* Hand cursor on hover */
  transition: background-color 0.3s; /* Smooth color change on hover */
}

.deal-card button:hover {
  background-color: #ff5722; /* Slightly lighter orange on hover */
}

.invite-highlight {
  text-decoration: none; /* Removes underline from links */
}

.invite-highlight-card {
  display: flex;
  align-items: center; /* Align items vertically */
  background-color: #E3F4FE; /* Gold background */
  border-radius: 20px; /* Rounded corners */
  padding: 10px 15px; /* Padding inside the card */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds subtle shadow */
  margin: 10px 10px; /* Adds space around the card */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.invite-highlight-card:hover {
  color: white;
  background-color: #b7def5; /* Slightly darker gold on hover */
}

.invite-highlight-icon {
  width: 25px;
  height: 25px; 
  margin-right: 10px; 
}

.invite-highlight-button {
  width: 18px;
}

.invite-highlight-title {
  color: #4BA7DF; /* White text */
  font-size: 12px; /* Sets the size of the title */
  font-weight: light; 
  margin: 0; /* Removes default margin */
  flex-grow: 1; /* Allows the title to fill available space */
}

.impact-highlight {
  text-decoration: none; /* Removes underline from links */
}

.impact-highlight-card {
  display: flex;
  align-items: center; /* Align items vertically */
  background-color: #006DB0; /* Gold background */
  border-radius: 20px; /* Rounded corners */
  padding: 10px 15px; /* Padding inside the card */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds subtle shadow */
  margin: 10px 10px; /* Adds space around the card */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.impact-highlight-card:hover {
  background-color: #006DB0; /* Slightly darker gold on hover */
}

.impact-highlight-icon {
  width: 80px;
  height: 80px; 
  margin-right: 0px; 
}

.impact-highlight-number {
  color: white; /* White text */
  font-size: 20px; /* Sets the size of the title */
  font-weight: bold; 
  margin-left: 10px; /* Removes default margin */
  flex-grow: 1; /* Allows the title to fill available space */
}

.impact-highlight-paragraph {
  color: white; /* White text */
  font-size: 12px; /* Sets the size of the title */
  font-weight: light; 
  margin: 10px; /* Removes default margin */
  flex-grow: 1; /* Allows the title to fill available space */
}

.impact-highlight-action {
  display: flex; /* Aligns children inline */
  align-items: center; /* Centers children vertically */
  justify-content: flex-start; /* Aligns items to the start */
  /* gap: 10px;  */
}

.impact-highlight-button-title {
  color: white; /* White text */
  font-size: 13px; /* Sets the size of the title */
  font-weight: light; 
  margin-left: 10px; 
  margin-bottom: 10px;
  flex-shrink: 0; /* Prevents the title from shrinking */
}

.impact-highlight-button {
  width: 13px;
  margin-bottom: 12px;
  margin-left: 5px;
  flex-shrink: 0; /* Prevents the title from shrinking */
}

/* Tablet view */
@media screen and (min-width: 768px) {
  /* Styles for tablet */
  .store-menu-button-title {
    margin-bottom: 20px !important;
  }

  .store-menu-button-image {
    margin-top: 10px;
  }

  .deal-card img {
    max-width: 45px; /* Make sure image doesn't overflow the card */
  }

  .deal-card h3 {
    font-size: 15px !important; 
  }

  .invite-highlight-card {
    width: 90%;
    margin: 0px auto;
  }

  .impact-highlight-card {
    width: 90%;
    margin: 0px auto;
  }

  .invite-highlight-title {
    font-size: 15px; /* Sets the size of the title */
  }

  .impact-highlight-number {
    font-size: 25px; /* Sets the size of the title */
  }
  
  .impact-highlight-paragraph {
    font-size: 15px; /* Sets the size of the title */
  }
  
  .impact-highlight-button-title {
    font-size: 15px;
  }
}

/* Desktop view */
@media screen and (min-width: 1024px) {
  /* Styles for desktop */
  .store-container { 
    background-image: url('../../assets/Store/storeBackgroundDesktop.png');
  }

  .store-menu-container {
    padding: 200px !important;
    margin-top: -100px !important;
    margin-bottom: -100px !important;
  }

  .store-button-image-container {
    /* background-color: rgb(210, 210, 210); */
    margin: 5px;
    border-radius: 10px;
    max-width: 280px !important;
  }

  .store-menu-button-title {
    margin-bottom: 30px !important;
    font-size: 20px !important; /* Sets the size of the title */
  }

  .store-menu-button-image {
    margin-top: 15px;
    max-width: 80px !important;
  }

  .deal-card img {
    margin-top: 30px;
    max-width: 50px; /* Make sure image doesn't overflow the card */
  }

  .deal-card h3 {
    margin-bottom: 30px;
    font-size: 15px !important; 
  }

  .invite-highlight-card {
    width: 75%;
    margin: 0px auto;
    margin-bottom: 50px;
  }

  .impact-highlight-card {
    width: 40%;
    margin-left: 210px;
    margin-top: 50px;
  }

  .invite-highlight-title {
    font-size: 15px; /* Sets the size of the title */
  }

  .impact-highlight-number {
    font-size: 25px; /* Sets the size of the title */
  }
  
  .impact-highlight-paragraph {
    font-size: 15px; /* Sets the size of the title */
  }
  
  .impact-highlight-button-title {
    font-size: 15px;
  }
}

@media screen and (min-width: 1424px) {
  .store-menu-container {
    padding: 200px !important;
    margin-top: -100px !important;
  }

  .impact-highlight-card {
    width: 40%;
    margin-left: 240px;
    margin-top: 50px;
  }
}