.gift-card-hero-image {
  width: 100%;
}

.gift-card-container {
  padding: 20px;
  /* background-color: #e6f7ff; */
  max-width: 400px;
  margin: 0 auto;
}

.gift-card-header {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  color: #454545;
}

.gift-card-paragraph {
  font-size: 13px;
  text-align: center;
  margin: 10px;
  /* font-weight: 600; */
  margin-bottom: 30px;
  color: #454545;
}

.gift-card-display-container {
  align-items: center;
  border-radius: 20px;
  padding: 10px 15px;
  /* box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2); */
  /* margin: 10px 5px; */
  margin-bottom: 35px;
  transition: background-color 0.3s;
}

.gift-card-image {
  width: 100%;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 40px;
}

.buy-btn {
  font-size: 13px;
  font-weight: 600;
  background-color: #A17FD4;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  margin-bottom: 10px;
  width: 100%;
}

.redeem-btn {
  font-size: 13px;
  font-weight: 600;
  background-color: #E9716B;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  margin-bottom: 10px;
  width: 100%;
}

.highlight-gift-card {
  color: #FFB516;
  font-weight: 600;
}