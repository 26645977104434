.consent-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px; /* Adjust margin as needed */
    font-size: 14px;
    font-weight: 400;
}

.consent-checkbox input {
    margin-right: 10px; /* Adds space between the checkbox and the text */
  }