
.booking-dashboard {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
    opacity: 0.9;
    border-top: 1px solid #bdbdbd;  /* Adjust color and thickness as needed */
    z-index: 8888;
    pointer-events: auto; /* Enables clicks for menu items */
}

.booking-dashboard-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 10px !important;
}

.booking-dashboard-prices p {
    letter-spacing: -0.5px;
    display: inline;  /* makes p tags inline */
    margin-right: 10px;  /* adds some spacing between them */
}

.booking-dashboard-discount {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: -5px;
}

.booking-dashboard-total {
    font-size: 14px;
    color: #555;
    text-decoration: line-through;
    /* margin-bottom: -5px; */
}

.booking-dashboard-date {
    letter-spacing: -0.5px;
    font-size: 13px;
    text-decoration: underline;
}

.booking-dashboard button {
    margin-right: 20px;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 15px;
    background-color: #d60052;
    color: white;
    width: 85px;
    height: 40px;
    border-radius: 10px;
    border: none;
}

.booking-dashboard-popup-buttons-container {
    display: flex;
    justify-content: space-between;
    padding: 10px; 
}

.booking-dashboard-popup-cart-button {
    /* font-weight: bold; */
    font-size: 15px;
    background-color: #d60052;
    color: white;
    width: 85px;
    height: 40px;
    border-radius: 10px;
    border: none;
}

.booking-dashboard-popup-continue-button {
    /* font-weight: bold; */
    font-size: 15px;
    background-color: #FFB516;
    color: white;
    width: 85px;
    height: 40px;
    border-radius: 10px;
    border: none;
}

.booking-dashboard-cart-icon-container {
    width: 48px;
    height: 45px;
    position: fixed;
    right: 0;
    top: 40%;
    transform: translateY(-50%);
    z-index: 1000;
    box-shadow: 2px 2px 5px grey;
    cursor: pointer;
    background-color: white;
    padding: 9px;
    border-top-left-radius: 10px;  /* Rounded top left corner */
    border-bottom-left-radius: 10px;  /* Rounded bottom left corner */
}

.booking-dashboard-cart-icon {
    width: 30px;
}

.booking-dashboard-popup-message-container {
    display: flex;
    justify-content: center;
}

.booking-dashboard-popup-message {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    width: 80%;
}

.booking-dashboard-popup-buttons-container {
    display: flex;
    justify-content: center;
    padding: 10px; 
}

.booking-dashboard-popup-ok-button {
    /* font-weight: bold; */
    font-size: 15px;
    background-color: #FFB516;
    color: white;
    width: 85px;
    height: 40px;
    border-radius: 10px;
    border: none;
}