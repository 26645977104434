/* SelectedCardDetails.css */

.selected-card-details {
    background: white;
    border-radius: 8px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    padding: 10px;
    max-width: 500px;
    margin: auto;
  }
  
  .selected-card-details-header2 {
    color: #333;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    margin-bottom: 20px;
    font-size: 15px !important;
  }
  
  .selected-card-details-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 20px;
  }
    
  .selected-card-details-date {
    font-size: 14px !important;
    color: #d60052;
    margin-bottom: 14px;
    margin-left: 10px;
  }

  .selected-card-details-description {
    font-size: 12px;
    color: #444;
    margin-top: -10px;
    margin-bottom: 20px;
    margin-left: 10px;
  }

  .adventure-section {
    margin-bottom: 20px;
  }
  
  .selected-card-details-header3 {
    font-weight: bold;
    font-size: 12px;
    color: #333;
    margin-top: 10px;
  }
  
  .adventure-section ul {
    list-style: circle;
    /* padding: 0;
    margin: 0; */
  }
  
  .adventure-section li {
      font-size: 11px;
    /* background: #f8f8f8;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 8px;
    color: #555; */
  }
  
  .selected-card-details-groupSize {
    /* font-weight: bold; */
    color: #333;
    font-size: 11px;
    margin-left: 10px;
  }