.coming-soon-container {
    background-image: url('../../../../assets/Store/comingSoonBackground.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top top;
}

.coming-soon {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 9vh;
    height: 70vh; /* or whatever height you prefer */
    text-align: center;
}

.coming-soon-logo {
    width: 150px;
}

.coming-soon-title {
    font-size: 20px;
    font-weight: 600;
    color: white;
    margin-bottom: 50px;
}

.coming-soon-description {
    width: 80%;
    font-size: 15px;
    font-weight: 600;
    color: white;
    margin-top: 50px;
}

.coming-soon-link {
    text-decoration: none;
    background-color: #A66D4F; /* you can use your theme color here */
    padding: 10px 20px;
    border-radius: 5px;
    color: white;
    font-weight: 600;
    transition: background-color 0.3s;
}

.coming-soon-link:hover {
    background-color: #8a5440; /* a darker shade of the button color for hover effect */
}
