.customer-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}

.customer-card {
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    align-items: center;
    width: 95%;
    transition: all 0.3s ease;
}

.customer-card:hover {
    border-color: #007bff; /* Light blue hover effect */
    box-shadow: 0 2px 5px rgba(0, 123, 255, 0.2); /* Slight shadow for depth */
}

.customer-card img {
    margin-right: 15px;
    width: 50px; /* Set image size */
    height: 50px;
    border-radius: 50%; /* Circular image */
}

.customer-info p {
    margin: 0px;
    font-size: 14px;
}

.customer-info strong {
    font-size: 16px; /* Slightly larger font for names */
}
