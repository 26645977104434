.about-background-one-container {
    background-image: url('../../../assets/Store/Services/pageBackground.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: right top;
}

@media (max-width: 767px) {
    .about-background-one-container {
        background-image: url('../../../assets/Store/Services/pageBackgroundMobile.png');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: right top;
    }
}

.about-container {
    margin: 20px;
    margin-top: 100px;
}

.about-page-icon {
    width: 35px;
    margin-bottom: 15px;
}

.about-page-heading {
    font-size: 37px;
    font-weight: 400;
    color:rgba(0, 0, 0, 0.719);
    text-align: left;
}

.about-page-paragraph {
    font-size: 16px;
    color:rgba(0, 0, 0, 0.719);
    text-align: left;
}

.centered-container {
    display: flex;
    justify-content: center;
}

.about-page-button {
    font-weight: bold;
    font-size: 14px;
    background-color: #47B2E7;
    color: white;
    width: 200px;
    height: 45px;
    border-radius: 18px;
    border: none;
    margin: 20px;
}

.about-page-main-photo {
    width: 100%;
}

.about-background-two-container {
    background-image: url('../../../assets/Store/Services/pageSubBackground.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: right top;
}

@media (max-width: 767px) {
    .about-background-two-container {
        background-image: url('../../../assets/Store/Services/pageSubBackgroundMobile-3.png');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: right top;
    }
}

.about-subpage-heading {
    margin-top: 40px;
    font-size: 13px;
    font-weight: bold;
    color:rgba(0, 0, 0, 0.318);
    text-align: center;
}

.about-subpage-heading-3 {
    margin-top: 50px;
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 300;
    color:rgba(0, 0, 0, 0.719);
    text-align: center;
}

.about-services-item {
    display: flex;
    margin: 10px;
    margin-bottom: 20px;
    align-items: flex-start; /* Align items at the top */
}
  
.about-services-item-icon {
    width: 65px;
    margin-left: -35px !important;
    margin-right: 15px;
}

.about-services-item-heading {
    font-size: 20px;
    color:rgba(0, 0, 0, 0.824);
}

.about-services-item-paragraph {
    font-size: 14px;
    color:rgba(0, 0, 0, 0.824);
}

.mission-container {
    /* margin: 20px;
    margin-top: 120px; */
    padding: 30px;
}

.about-mission-header {
    margin-top: 50px;
    margin-bottom: 30px;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.719)
}

.about-mission-paragraph {
    font-size: 16px;
    text-align: center;
    color: rgba(0, 0, 0, 0.719);
}

.about-safety-container {
    padding: 20px;
}

.about-safety-heading {
    margin-top: 80px;
    margin-bottom: 40px;
    font-size: 13px;
    font-weight: bold;
    color:rgba(0, 0, 0, 0.318);
    text-align: center;
}

.about-safety-heading-3 {
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 18px;
    font-weight: 300;
    color:rgba(0, 0, 0, 0.719);
    text-align: center;
}

.about-background-four-container {
    background-image: url('../../../assets/Store/Services/pageSubBackground.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
}

@media (max-width: 767px) {
    .about-background-four-container {
        background-image: url('../../../assets/Store/Services/pageSubBackgroundMobile-3.png');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center center;
    }
}

.partner-container, .join-us-container, .franchise-opportunity-container {
    padding: 30px;
}

.partner-heading, .join-us-heading, .franchise-heading {
    font-size: 23px;
    color:rgba(0, 0, 0, 0.824);
}

.partner-message, .join-us-message, .franchise-message {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.719);
}

.about-partner-button {
    font-size: 14px;
    font-weight: 500;
    color: #4CA7DF;
    background: none;
    border: none;
    cursor: pointer;
    margin-left: -5px;
}

.about-join-us-button {
    font-size: 14px;
    font-weight: 500;
    color: #4CA7DF;
    background: none;
    border: none;
    cursor: pointer;
    margin-left: -5px;
}

.about-franchise-button {
    font-size: 14px;
    font-weight: 500;
    color: #4CA7DF;
    background: none;
    border: none;
    cursor: pointer;
    margin-left: -5px;
}


/* Responsive CSS */
@media (min-width: 400px) {
    .about-container {
        margin-top: 120px;
    }

    .about-page-heading {
        font-size: 38px;
        font-weight: 300;
    }
    
    .about-page-paragraph {
        font-size: 16px;
    }

    .about-subpage-heading {
        font-size: 15px;
    }
    
    .about-subpage-heading-3 {
        font-size: 23px;
        margin-bottom: 40px;
    }
    
    .about-services-item-heading {
        font-size: 22px;
    }
    
    .about-services-item-paragraph {
        font-size: 16px;
        margin-bottom: 20px;
    }
    
    .about-safety-heading {
        font-size: 15px;
    }
    
    .about-safety-heading-3 {
        font-size: 23px;
        margin-bottom: 40px;
    }
}