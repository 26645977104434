.options-container {
    /* background-color: #f5f5f5; */
    border: 2px solid #FFB516;
    border-radius: 10px;
    /* margin: 5px; */
    padding: 10px;
}

.option-group {
    margin-bottom: 15px;
}

.options-container h3 {
    font-size: 13px;
    margin-left: 15px;
    margin-bottom: 10px;
    font-weight: 600;
}

.options-header {
    text-align: center;
    font-size: 17px !important;
    font-weight: 600;
    max-width: 200px;
    margin: 0 auto;
    margin-bottom: 20px;
}

/* CSS for selected pet size buttons */
.selected-size-button {
    background-color: #FFB516; /* Define the selected background color */
    color: white; /* Define the text color for selected buttons */
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
}

/* CSS for selected grooming package buttons */
.selected-package-button {
    background-color: #FFB516; /* Define the selected background color */
    color: white; /* Define the text color for selected buttons */
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
}

.options-container li {
    font-size: 12px;
}

.selected {
    background-color: #FFB516; /* Set the background color you prefer */
    color: white; /* Set the text color for selected buttons */
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
}

.style-buttons {
    margin-bottom: 15px;
}

.teeth-preferences-photo {
    max-width: 70%; /* Set the maximum width to 100% of its container */
    height: auto; /* Allow the height to adjust automatically based on the aspect ratio */
    display: block; /* Remove any extra spacing that may occur by default */
    margin-bottom: 10px;
    margin-left: 20px;
    padding: 10px;
}

.style-images {
    margin-top: 10px;
    margin-bottom: 10px;
}

.style-images img {
    object-fit: cover;
    width: 100px;
    height: 100px;
    margin: 5px;
    border-radius: 50%;
    margin-bottom: 7px;
}

@media (max-width: 420px) {
    .style-images img {
        width: 90px;
        height: 90px;
        margin: 2px;
    }
}

.special-conditions {
    text-align: center;
    margin: 15px;
}