.booking-side-icon-container {
    width: 48px;
    height: 45px;
    position: fixed;
    right: 0;
    top: 60%;
    transform: translateY(-50%);
    z-index: 1000;
    box-shadow: 2px 2px 5px grey;
    cursor: pointer;
    background-color: #4CA7DF;
    padding: 9px;
    border-top-left-radius: 10px;  /* Rounded top left corner */
    border-bottom-left-radius: 10px;  /* Rounded bottom left corner */
}

.booking-side-icon-text {
    width: 30px;
    font-size: 12px;
    font-weight: 600;
    color: white;
}