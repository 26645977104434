.service-config-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 15px;
}

.service-config-container h2 {
    color: #404040;
    font-size: 20px;
    text-align: center;
}

.service-config-container form {
    padding: 15px;
}

.service-config-container label {
    font-size: 14px;
}

.service-config-container input {
    margin-left: 10px;
    max-width: 120px;
    text-align: center;
}

.service-config-button {
    font-weight: bold;
    font-size: 14px;
    background-color: #47B2E7;
    color: white;
    margin: 20px auto;
    width: 130px;
    height: 50px;
    border-radius: 10px;
    border: none;
}