.payment-historys-section {
    padding: 10px;
}

.payment-history {
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.payment-history:hover {
    background-color: #f1f1f1; /* Light gray background on hover */
}

.payment-history-image {
    width: 40px;
    height: 30px;
    margin-right: 15px;
}

.payment-history-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
}

.cardName-cardDefault {
    display: flex;
}

.payment-history-toggle-buttons button {
    border: none;
    border-radius: 25px;
    color: #757575;
    background-color: white;
    margin: 11px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    font-weight: 500;
}

.payment-history-toggle-buttons button.active {
    background-color: #E9716B;
    color: white;
}

.default-badge-image {
    width: 25px;
    margin-left: 5px;
}

.payment-history-name {
    font-size: 14px;
    font-weight: 500;
}

.payment-history-type {
    font-size: 15px;
    margin-bottom: 2px;
    /* font-weight: 500; */
}

.payment-history-date {
    font-size: 14px;
    margin-bottom: 0;
}

.payment-history-originalPrice {
    margin-bottom: 0;
    margin-right: 6px;
    font-size: 14px;
    text-align: right;
}

.payment-history-badges-container {
    display: flex;
    gap: 10px;
}

.status-badge {
    margin-bottom: 2px;
    font-size: 14px;
    font-weight: 500;
    text-align: right;
    padding-right: 6px;
    padding-left: 6px;
    border-radius: 20px;
}

.status-badge.completed {
    background-color: #4CA7DF; 
    color: white; 
    border: 1px solid #4CA7DF; /* Solid border for a more defined look */
}

.status-badge.canceled {
    background-color: #E9716B; 
    color: white; 
    border: 1px solid #E9716B; /* Solid border for a more defined look */
}

.status-badge.pending {
    background-color: #FFB516; 
    color: white; 
    border: 1px solid #FFB516; /* Solid border for a more defined look */
}

.payment-history-badge {
    display: inline-block;
    padding: 5px 10px;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 500;
    background-color: #e6e6e6;
    border-radius: 10px;
}

.payment-history-breakline {
    height: 1px;
    color: inherit;
    border: 0;
    border-top: var(--bs-border-width) solid;
    opacity: 0.2;
    margin: 10px 0;
}

.add-payment-history-button-container {
    text-align: right;
}

.add-payment-history-button {
    font-weight: bold;
    font-size: 14px;
    background-color: #47B2E7;
    color: white;
    width: 150px;
    height: 35px;
    margin-top: 20px;
    border-radius: 10px;
    border: none;
}

.add-payment-history-button:hover {
    background-color: rgb(28, 116, 170);
}

.payment-history-message {
    text-align: center;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 500;
}

.payment-history-search-input {
    margin-left: 10px;
    width: 60%;
    padding: 10px;
    border: 2px solid #DDD;
    border-radius: 5px;
    font-size: 12px;
}
