.family-section {
  /* margin-bottom: 30px; */
  padding: 15px;
  border-radius: 10%;
  /* background-color: #f7eee5; */
  /* box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3); */
}

.family-header {
  display: relative;
  align-items: center;
  justify-content: space-between;
}

.title-and-button-container {
  display: flex;
  justify-content: center;
  position: relative; /* Needed to position the edit button relative to this container */
}

.family-title {
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  color: #A66D4F;
}

.family-edit-button {
  font-size: 13px;
  color: #A66D4F;
  border: none;
  background: none;
  position: absolute; /* Position the button absolutely to the right of the title */
  right: 10%; /* Align the button to the right edge of the title-and-button-container */
  top: 50%;
  transform: translateY(-50%); /* Offset the button by half its height for perfect vertical centering */
}

.family-edit-image {
  width: 11px;
  margin-left: 2px;
  margin-bottom: 2px;
}

.family-member-section {
  /* min-height: 250px; */
  margin-top: 10px;
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.family-member {
  flex-basis: 25%;
  text-align: center;
  margin: 5px;
}

.family-title-image {
  width: 100%;
}

.family-member-image {
  width: 80px;
  height: 80px;
  padding: 3px;
  margin: 5px;
  object-fit: cover;
  border-radius: 50%;
  /* border: 1px solid #A66D4F; */
}

@media (min-width: 400px) {
  .family-member-section {
    margin-top: 20px;
  }

  .family-member-image {
    width: 90px;
    height: 90px;
  }
}

@media (min-width: 450px) {
  .family-member-section {
    margin-top: 20px;
  }

  .family-member-image {
    width: 100px;
    height: 100px;
  }
}

.family-member-name {
  margin-top: -10px;
  margin-left: 60px;
  font-size: 12px;
  color: rgb(75, 75, 75);
}

.family-member-type {
  margin-top: -10px;
  margin-left: 60px;
  font-size: 10px;
  font-weight: 400;
  color: #A66D4F;
}

.profile-menu-container {
  /* background-color: rgb(210, 210, 210); */
  margin-bottom: 100px !important;
}

.profile-button-image-container {
  /* background-color: rgb(210, 210, 210); */
  background-color: white;
  text-align: center;
  margin: 5px;
  border-radius: 10px;
}

.profile-menu-button-image {
  width: 50%;
  padding: 3px;
  margin: 5px;
}

.profile-card-container {
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
  justify-content: space-evenly;
  /* flex-wrap: wrap; */
}

.profile-card {
  flex: 0 1 48%;
  max-width: 80px;
  /* margin: 10px; */
  padding: 5px;
  /* border: 1px solid #e0e0e0; */
  border-radius: 20px;
  /* box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); */
  transition: 0.3s;
  text-align: center; /* optional: centers the text and button inside the card */
}

.profile-card:hover {
  transform: translateY(-5px);
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.15);
}

.profile-card img {
  max-width: 33px; /* Make sure image doesn't overflow the card */
  border-radius: 10px; /* Optional: Rounded corners for images */
  margin-bottom: 5px;
}

.profile-card h3 {
  font-size: 12px; /* Adjust as needed */
  /* margin-bottom: 10px; */
}

.profile-card p {
  font-size: 12px; /* Adjust as needed */
  /* margin-bottom: 20px; */
}

.profile-card button {
  font-size: 14px;
  background-color: #ff4500; /* Orange color, adjust as needed */
  color: #ffffff; /* White text */
  padding: 10px 20px;
  border: none;
  /* border-radius: 5px; Slight rounding of button corners */
  cursor: pointer; /* Hand cursor on hover */
  transition: background-color 0.3s; /* Smooth color change on hover */
}

.profile-card button:hover {
  background-color: #ff5722; /* Slightly lighter orange on hover */
}


.account-title {
  font-size: 20px;
  text-align: center;
}

.account-info {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 13px;
}

.info-card {
  /* background-color: #47B2E7; */
  color: #4CA7DF;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;  
  align-items: center;
}

.info-settings-card {
  /* background-color: #4A870C; */
  color: black;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;  
  align-items: center;
  margin-right: 10px;
}

.info-settings-card select {
  padding: 0px;
  margin-right: -5px;
  color: #FFB516;
  border: none;
  font-size: 13px;
  width: auto;
  text-align: center; /* Aligns content within the container to the right */

  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.info-settings-card select:focus {
  color: #FFB516;
  box-shadow: none;
  outline: none;
}

.info-friends-card {
  /* background-color: #FFB516; */
  color: #FFB516;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;  
  align-items: center;
}

.info-guides-card {
  /* background-color: brown; */
  color: brown;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;  
  align-items: center;
}

.info-signOut-card {
  /* background-color: rgb(225, 2, 2); */
  color: red;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;  
  align-items: center;
}

.info-card-icon {
  max-width: 30px;
  margin: 3px;
  padding: 3px;
}

.info-card-title {
  color: rgb(119, 119, 119);
  font-size: 12px;
  margin-left: 5px;
  margin-bottom: 0px;
  flex-grow: 1;
}

.info-card-info {
  color: rgb(88, 88, 88);
  font-size: 12px;
  margin-left: 5px;
  margin-bottom: 0px;
  flex-grow: 1;
}

.info-location-card-info {
  color: #FFB516;
  font-size: 12px;
  margin-left: 5px;
  margin-bottom: 0px;
  flex-grow: 0;
}

.info-signOut-title {
  color: rgb(199, 0, 0);
  font-size: 12px;
  text-align: right;
  margin-right: 10px;
  margin-bottom: 0px;
  flex-grow: 1;
}

.profile-breakline {
  height: 1px;
  margin: 0 5px;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.2;
}

.link-no-underline {
  text-decoration: none;
}  

.info-card-button {
  width: 10px;
  margin-right: 20px;
  margin-bottom: 3px;
}

.info-card a {
  /* display: block; */
  /* margin-top: 10px; */
  color: #333;
  text-decoration: none;
}

.info-card a:hover {
  text-decoration: underline;
}

.profile-popup-message {
  font-size: 12px;
  text-align: center;
  /* color: rgb(199, 0, 0); */
}

.profile-popup-actions {
  display: flex;
  justify-content: space-between; /* This will put the buttons on either end */
  padding: 10px; /* Add some padding around the buttons */
}

.profile-popup-actions-confirmation {
  display: flex;
  justify-content: center;
  padding: 10px; /* Add some padding around the buttons */
}

.profile-popup-yes-button {
  padding: 5px 10px;
  border-radius: 5px;
  background-color: rgb(199, 0, 0);
  border: none;
  color: white;
  font-size: 13px;
  font-weight: 600;
}

.profile-popup-no-button {
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #FFB516;
  border: none;
  color: white;
  font-size: 13px;
  font-weight: 600;
}

/* Popup overall styling */
.popup-content {
  background: white;
  padding: 20px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  border-radius: 10px;
  width: 75%;
  max-width: 400px; /* Adjust width as necessary */
  margin: 20px auto;
  position: relative;
}

/* Header of the popup */
.profile-popup-header {
  font-size: 15px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

/* Input fields styling */
.popup-content input {
  font-size: 13px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 20px;
  display: block;
}

.sms-agreement-container {
  font-size: 12px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.sms-agreement-container {
  margin-top: 10px;
}

.sms-agreement-label {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.sms-agreement-label input[type="checkbox"] {
  margin-left: 5px;
}

.sms-agreement-text {
  line-height: 1.5;
  text-align: left;
  margin-left: 15px;
}

.profile-popup-update-button-yes, .profile-popup-cancel-button {
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #FFB516;
  border: none;
  color: white;
  font-size: 13px;
  font-weight: 600;
}

.profile-popup-update-button-no, .profile-popup-confirm-button {
  padding: 5px 10px;
  border-radius: 5px;
  background-color: rgb(199, 0, 0);
  border: none;
  color: white;
  font-size: 13px;
  font-weight: 600;
}

.profile-popup-update-button-ok {
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #FFB516;
  border: none;
  color: white;
  font-size: 13px;
  font-weight: 600;
}

.profile-popup-update-button:hover {
  background-color: #e6a700;
}