/* Support.css */

.support-backdrop {
  background-color: #4CA7DF;
}

.support-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .support-card-container-one {
    /* align-items: center; */
    background-color: white;
    border-radius: 20px;
    padding: 10px 70px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    margin: 5px 25px;
    margin-bottom: 30px;
    transition: background-color 0.3s;
  }
  
  .support-heading {
    font-size: 24px;
    color: white;
  }

  .support-message {
    color: white;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 20px;
  }

  .support-icon {
    width: 20px; /* Adjust size as needed */
    height: auto;
    margin-right: 8px;
    vertical-align: middle;
  }

  .support-message-black {
    color: black;
  }

  .support-link {
    cursor: pointer;
    display: inline-block;
    margin-top: 5px; /* Adjust the value to create the desired gap */
  }

  .support-card-container-two {
    /* align-items: center; */
    background-color: white;
    border-radius: 20px;
    padding: 10px 20px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    margin: 5px 25px;
    margin-bottom: 30px;
    transition: background-color 0.3s;
  }

  .support-text-now-button {
    font-size: 14px;
    font-weight: bold;
    background-color: #E9716B;
    color: #fff;
    padding: 10px 5px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    margin: 5px auto;
    width: 40%;
  }
  
  .contact-info {
    margin-bottom: 20px;
  }
  
  .contact-heading {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .contact-details {
    margin-bottom: 20px;
  }
  
  .support-card-container-three {
    background-image: url('../../../assets/Dashboard/Profile/components/Wallet/containerTwoBackdrop.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
    margin-top: -1px;
    padding: 20px;
  }

  .support-faq-heading {
    margin-top: 50px;
    font-size: 24px;
    color: rgb(75, 75, 75);
    text-align: center;
  }