.family-member-edit-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

/* Assuming .back-button and .delete-button are your button classes */
.back-button {
    /* your existing styles */
}

.delete-button {
    margin-left: auto;
    /* other styles for delete button */
}

.family-member-edit-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.family-member-edit-header h1 {
    margin: 0;
    font-size: 1.5em;
}

.image-upload-container {
    display: flex; /* Establish this container as a flex container */
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    margin: 0 auto; /* Add margin for spacing and auto for automatic margins on both sides */
    margin-bottom: 10px;
    width: fit-content; /* Fit to the size of the image */
    position: relative;
}

.profile-image {
    object-fit: cover;
    display: block; /* Remove inline default */
    max-width: 200px; /* Set a max-width for your image */
    height: auto; /* Maintain aspect ratio */
    border-radius: 50%; /* Make it round */
    margin: 10px;
}

.edit-badges-container {
    display: flex;
    justify-content: center;
    /* margin-left: 30px; */
    margin: 20px;
    flex-direction: row; /* ensures badges are in a row */
    gap: 2px; /* adjust as needed, adds space between badges */
}

.family-member-edit-membership-button {
    padding: 5px 10px;
    margin: 5px;
    border-radius: 5px;
    background-color: #E9716B;
    border: none;
    color: white;
    font-size: 14px;
    font-weight: 600;
}

.family-member-edit-daycare-button {
    padding: 5px 10px;
    margin: 5px;
    border-radius: 5px;
    background-color: #FFB516;
    border: none;
    color: white;
    font-size: 14px;
    font-weight: 600;
}


.image-upload-label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7); /* Slight white overlay */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0; /* Hide by default */
}

.image-upload-label:hover {
    opacity: 1; /* Show on hover */
}

.image-upload {
    display: none; /* Hide the actual input */
}
