.getStarted-container {
    margin-bottom: 30px;
    padding: 40px;
    min-height: 375px;
}

.getStarted-heading {
    margin-top: 45px;
    font-size: 20px;
    font-weight: 600;
    color:rgba(0, 0, 0, 0.824);
    text-align: left;
}

.getStarted-paragraph {
    font-size: 14px;
    color:rgba(0, 0, 0, 0.719);
    margin-bottom: 30px;
}

.bold-text {
    font-weight: bold;
    color: #E9716B;
}

.getStarted-button {
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 14px;
    background-color: #E9716B;
    color: white;
    width: 170px;
    height: 45px;
    border-radius: 20px;
    border: none;
}

.getStart-subparagraph {
    font-size: 13px;
    font-style: italic;
    color:rgba(0, 0, 0, 0.719);
    margin-top: -15px;
}

@media (min-width: 400px) {
    .getStarted-heading {
        font-size: 23px;
        font-weight: 500;
    }
    
    .getStarted-paragraph {
        font-size: 15px;
    }
    
    .getStart-subparagraph {
        font-size: 14px;
    }
}

@media screen and (min-width: 668px) {
    .getStarted-container {
        padding: 100px;
        min-height: 675px;
    }
    
    .getStarted-heading {
        margin-top: 130px;
        font-size: 30px;
        text-align: left;
    }
    
    .getStarted-paragraph {
        font-size: 20px;
    }
    
    .getStarted-button {
        font-size: 18px;
        width: 180px;
        height: 50px;
    }
    
    .getStart-subparagraph {
        font-size: 20px;
    }
}