.login-header {
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 15px;
    color:rgba(0, 0, 0, 0.824);
    text-align: center;
}

.login-email {
    font-size: 14px;
    margin-bottom: 10px;
}

.login-password {
    font-size: 14px;
}

.login-button {
    margin-top: 30px;
    font-weight: bold;
    font-size: 14px;
    color: white;
    background-color: #FFB516 !important;
    width: 100%;
    height: 45px;
    border: none;
    /* border-radius: 20px; */
    /* border-style: solid; */
}

.firebase-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 45px;
}

.firebase-container button {
  background-color: white;
  color: black;
}

.login-button-image {
  width: 50px;
}

.login-button-text {
  font-size: 13px;
  margin-top: 3px;
}

.login-message-container {
    margin: 10px;
    margin-top: 35px;
    margin-bottom: 30px;
    font-size: 14px;
}

.login-message-container a {
    color: #4CA7DF;
    text-decoration: none;
}

.account-login-container {
  margin-top: 30px;
}

.sign-up-section {
    background-image: url('../../assets/Landing/landingPageSubBackground.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
  }
  
  @media (max-width: 767px) {
    .sign-up-section {
        background-image: url('../../assets/Landing/landingPageSubBackgroundMobile-3.png');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center center;
    }
  }

  .sms-sign-in-modal, .sms-code-verification-modal {
    top: 30%;
  }
  
  .sms-sign-in-modal-close-button, .sms-code-verification-modal-close-button {
    padding: 5px 10px;
    border-radius: 5px;
    background-color: rgb(199, 0, 0);
    border: none;
    color: white;
    font-size: 13px;
    font-weight: 600;
  }

  .sms-sign-in-modal-send-button, .sms-code-verification-modal-verify-button {
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #FFB516;
    border: none;
    color: white;
    font-size: 13px;
    font-weight: 600;
  }