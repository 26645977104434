.spinner-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFB516;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
    z-index: 9999;
}

.spinner-container.active {
    opacity: 1;
    pointer-events: auto;  /* Make sure spinner receives click events */
}

.spinner-image.cat-spinner {
    width: 55px;  /* Adjust to your desired size */
    height: auto;  /* Maintain aspect ratio */
}

.spinner-image.dog-spinner {
    width: 80px;  /* Adjust to your desired size */
    height: auto;  /* Maintain aspect ratio */
}

