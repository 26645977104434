.smart-search-bar {
    position: relative;
    width: 100%;
    text-align: center;
}

.smart-search-bar input {
    width: 75%;
    padding: 10px;
    font-size: 13px;
    border: 2px solid #ccc;
    border-radius: 15px;
}

.shuttle-booking-time input {
    width: 115px !important;
    height: 45px;
}

.suggestions-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    margin-top: 10px;
    list-style: none !important;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: white;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
}

.suggestions-list li {
    padding: 5px;
    margin-left: -30px;
    cursor: pointer;
}

.suggestions-list li.active,
.suggestions-list li:hover {
    background-color: #FFB516;
    color: white;
}
