.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Dim the background */
    z-index: 1000; /* Make sure it's on top of other content */
  }

  .booking-dashboard-popup-message {
    text-align: center;
  }
  
  .popup-content {
    position: fixed !important;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    z-index: 1001; /* Above the overlay */
  }
  
  .popup-close {
    display: none;
    position: absolute;
    top: 9px;
    right: 9px;
    border: none;
    background: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  