.edit-profile {
    /* Style your main container */
  }
  
  .edit-family-title {
    margin: 30px;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: #A66D4F;
  }
  
  .edit-family-member-section {
    /* Style the container for the family members */
  }
  
  .edit-family-member {
    display: flex;
    align-items: center;
    /* margin: 5px; */
    padding: 10px;
    /* Additional styles */
  }
  
  .edit-family-member-image {
    object-fit: cover;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-right: 10px;
    margin-left: 5px;
  }
  
  .edit-family-member-details {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .edit-family-member-name {
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 2px;
    /* Style the family member's name */
  }
  
  .edit-family-member-type {
    /* margin-top: -10px; */
    font-size: 11px;
    font-weight: 400;
    color: #A66D4F;
  }
  
  .edit-family-member-button {
    background-color: #A66D4F;
    border: none;
    border-radius: 10px;
    color: white;
    font-size: 12px;
    margin-right: 5px;
    padding: 5px 10px; /* Add padding for better touch area and appearance */
    cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
    transition: background-color 0.3s; 
  }

  .edit-family-member-button:hover {
    background-color: #e6a700; /* Slightly darker color on hover */
}

.edit-family-add-new-container {
  text-align: right;
}

.edit-family-add-new {
  font-weight: bold;
  font-size: 14px;
  background-color: #47B2E7;
  color: white;
  width: 150px;
  height: 35px;
  margin-top: 20px;
  border-radius: 10px;
  border: none;
}

.edit-family-add-new:hover {
  background-color: rgb(28, 116, 170);
}

  .edit-profile-breakline {
    height: 1px;
    margin: 0 5px;
    color: inherit;
    border: 0;
    border-top: var(--bs-border-width) solid;
    opacity: 0.2;
  }
  
  .contact-info-form {
    /* Style the form to update contact information */
  }
  
  .input-group {
    margin-bottom: 10px;
    /* Additional styles */
  }
  
  .edit-family-save-changes {
    /* Style the save changes button */
  }
  
.badges-container {
    display: flex;
    flex-direction: row; /* ensures badges are in a row */
    gap: 10px; /* adjust as needed, adds space between badges */
}

  .badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.375rem;
    margin-right: 0.5em;
  }

  .membership-verified {
    background-color: #E9716B; /* A deep purple to signify exclusivity */
    color: #ffffff; /* White text for contrast */
    border: 1px solid #E9716B; /* Solid border for a more defined look */
  }
  
  .daycare-verified {
    background-color: #ffdf85; 
    color: #805300; 
    border: 1px solid #ffdf85; /* Solid border for a more defined look */
  }
  
  .vaccination-verified {
    background-color: #85ffaf; 
    color: #005229; 
    border: 1px solid #85ffaf; /* Solid border for a more defined look */
  }

  .membership-unverified {
    background-color: #E9716B; /* A lighter purple for unverified */
    color: #ffffff; /* Deep purple text to match the verified badge */
    border-color: #E9716B; /* Dashed border to indicate it's not yet verified */
    opacity: 0.2; /* Slightly translucent */
  }

  .daycare-unverified {
    background-color: #fff3cd; /* Lighter background for unverified */
    color: #806b00; /* Lighter text color for unverified */
    border-color: #ffdf85; /* Border color same as verified background */
    opacity: 0.2; /* Optional: reduces opacity for unverified badges */
  }
  
  .vaccination-unverified {
    background-color: #e6ffec; /* Lighter background for unverified */
    color: #003d1a; /* Lighter text color for unverified */
    border-color: #85ffaf; /* Border color same as verified background */
    opacity: 0.2; /* Optional: reduces opacity for unverified badges */
  }
  