.register-header {
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 15px;
    color:rgba(0, 0, 0, 0.824);
    text-align: center;
}

.register-name {
    font-size: 14px;
    margin-bottom: 10px;
}

.register-email {
    font-size: 14px;
    margin-bottom: 10px;
}

.register-password {
    font-size: 14px;
    margin-bottom: 10px;
}

.register-confirmPassword {
    font-size: 14px;
    margin-bottom: 10px;
}

.register-button {
    margin-top: 30px;
    font-weight: bold;
    font-size: 14px;
    background-color: #FFC34A;
    color: white;
    width: 100%;
    height: 45px;
    border: none;
    margin-bottom: 30px;
    /* border-radius: 20px; */
    /* border-style: solid; */
}

.register-message-container {
    margin: 10px;
    margin-top: 35px;
    margin-bottom: 30px;
    font-size: 14px;
}

.register-message-container a {
    color: #4CA7DF;
    text-decoration: none;
}

.sign-up-section {
    background-image: url('../../../assets/Landing/landingPageSubBackground.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
  }
  
  @media (max-width: 767px) {
    .sign-up-section {
        background-image: url('../../../assets/Landing/landingPageSubBackgroundMobile-3.png');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center center;
    }
  }