.checkout-backdrop {
  background-color: #4CA7DF;
  /* padding-bottom: 15px; */
}

.checkout-container {
    padding: 15px;
    max-width: 600px;
    margin: auto;
}

.checkout-summary-container, .checkout-contact-container, .checkout-payment-container {
    align-items: center;
    background-color: white;
    border-radius: 20px;
    padding: 10px 15px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
    margin-bottom: 15px;
    transition: background-color 0.3s;
}

.checkout-payment-header-container {
    display: flex;
    justify-content: space-between;
}

.checkout-items-container {
    display: flex;
    flex-direction: column;
    padding: 5px;
}

.checkout-item-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.checkout-item-discountSummary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -12px;
    margin-bottom: 10px;
}

.checkout-summary-container h2, .checkout-contact-container h2, .checkout-payment-container h2 {
    font-size: 14px;
    font-weight: 600;
}

.checkout-item-description {
    display: flex;
    align-items: center;
    /* flex-direction: column; */
}

.checkout-item-service {
    font-size: 13px;
    margin-right: 3px;
}

.checkout-item-name {
    font-size: 13px;
    font-style: italic;
    color: #333; /* Adjust the color to match your design */
    margin-left: 3px;
    margin-right: 3px;
}

.checkout-item-date {
    font-size: 12px;
    color: #666; /* Adjust the color to match your design */
}

.checkout-item-originalPrice {
    font-size: 12px;
}

.checkout-item-discountedPrice {
    font-size: 12px;
    color: #C70000;
}

.checkout-subtotal-cost {
    font-size: 12px;
    text-align: right;
}

.checkout-total-cost {
    margin-top: 5px;
    font-size: 12px;
    text-align: right;
    padding: 5px;
}

.checkout-total-saving {
    margin-top: -3px;
    font-size: 11px;
    text-align: right;
}

.checkout-total-saving-highlight {
    color: #C70000;
}

.checkout-breakline {
    height: 2px;
    margin: 0 auto;
    color: inherit;
    border: 0;
    border-top: var(--bs-border-width) solid;
    opacity: 0.2;
    margin-bottom: 10px;
}

.checkout-contact-card {
  color: black;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;  
  align-items: center;
  margin-right: 10px;
}

.checkout-card-title {
    color: rgb(119, 119, 119);
    font-size: 13px;
    margin-left: 10px;
    margin-bottom: 0px;
}

.checkout-card-info {
    color: rgb(88, 88, 88);
    font-size: 13px;
    margin-bottom: 0px;
}

.checkout-card-button {
    padding: 5px 10px;
    background: none;
    border: none;
    color: #4CA7DF;
    font-size: 12px;
}

.checkout-card-display {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    border-radius: 8px;
    /* padding: 12px; */
    margin-top: 10px;
    margin-bottom: 10px;
    /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); */
  }

  .checkout-view-button-container {
    position: absolute;
    right: 10%;
    margin-top: -30px;
  }

  .checkout-card-logo {
    width: 37px; /* Adjust size as needed */
  }
  
  .checkout-card-info {
    padding-left: 12px; /* Adjust spacing as needed */
  }
  
  .checkout-card-number {
    font-size: 13px;
    /* font-weight: 600; */
    color: #4CA7DF;
  }
  
  .checkout-card-details {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    color: #666;
    margin-top: -3px;
  }
  
  .checkout-add-card-details {
    /* display: flex; */
    justify-content: left;
    font-size: 13px;
    color: #666;
    margin-top: -3px;
  }

  .checkout-form-container {
    background-image: url('../../../../assets//Dashboard/Profile//components/Cart/containerBackdrop.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
    margin-top: -1px;
    text-align: right;
    padding: 20px;
  }

  .checkout-terms-and-conditions {
    margin-top: 40px;
    margin-bottom: 15px;
    text-align: right;
  }

  .checkout-confirmation-container {
    text-align: right;
  }

  .checkout-confirmation-container button {
    font-size: 13px;
    font-weight: bold;
    background-color: #4CA7DF;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 0px;
    width: 170px;
  }

  .checkout-confirmation-container button:disabled {
    font-size: 13px;
    font-weight: bold;
    background-color: #c6c6c6;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 0px;
    width: 170px;
  }

  .checkout-policies-container a {
    color: #4CA7DF;
    text-decoration: none;
    margin-left: 5px;
  }

  .checkout-policies-label {
    font-size: 12px;
  }

  /* Add this CSS to vertically align label and input elements */
.checkout-policies-container label {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-end;
}

/* Optionally, you can adjust the margin between the checkbox and the text */
.checkout-policies-container label input[type="checkbox"] {
    margin-right: 5px;
}

.use-balance-option {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Pushes elements to the edges */
  margin: 5px 10px;
}

.balance-text {
  font-size: 13px;
}

.toggle-switch {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: relative;
  width: 34px;
  height: 18px;
  background-color: #ccc;
  border-radius: 15px;
  transition: background-color 0.2s;
}

.slider:before {
  content: "";
  position: absolute;
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.2s;
}

input:checked + .slider {
  background-color: #4CA7DF;
}

input:checked + .slider:before {
  transform: translateX(16px);
}