.open-browser-prompt {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 9999; /* Ensure it's on top of everything */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    padding: 20px;
  }

  .browser-prompt-logo {
    width: 150px;
  }
  
  .open-browser-prompt h2 {
    margin-bottom: 20px;
  }
  
  .open-browser-prompt button {
    margin-top: 10px;
    padding: 15px 30px;
    background-color: #FFB516;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
  }
  