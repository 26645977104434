.invite-container {
    padding: 20px;
    /* background-color: #e6f7ff; */
    max-width: 400px;
    margin: 0 auto;
}

.invite-hero-image {
    width: 100%;
}

.invite-header {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    color: #454545;
}

.invite-paragraph {
    font-size: 13px;
    text-align: center;
    margin: 10px;
    /* font-weight: 600; */
    margin-bottom: 30px;
    color: #454545;
}

.highlight-invite {
    color: #4CA7DF;
    font-weight: 600;
}

.highlight-referrals {
    color: #4CA7DF;
    font-weight: 600;
}

.referrals-count {
    font-size: 11px;
    /* font-weight: 500; */
    color: #9a9a9a;
    text-align: center;
    margin: 5px;
    margin-bottom: 10px;
}

.reward-container {
    align-items: center;
    border-radius: 20px;
    padding: 10px 15px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    margin: 10px 5px;
    margin-bottom: 35px;
    transition: background-color 0.3s;
}

.rewards {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.reward-card {
    border: 1px solid #e0e0e0;
    border-radius: 20px;
    padding: 10px;
    text-align: center;
    flex: 1;
    margin: 0 5px;
}

.reward-card.highlighted {
    background: #4CA7DF;
    color: white;
}

/* .reward-card.greyed-out {
    background: #e0e0e0;
    color: #999;
} */

.reward-amount {
    color: #a8a8a8;
    font-size: 13px;
    margin-bottom: 10px;
}

.reward-amount.highlighted {
    color: white;
    font-weight: 600;
}

.reward-message {
    color: #a8a8a8;
    font-size: 10px;
    margin-bottom: 10px;
}

.reward-message.highlighted {
    color: white;
    font-weight: 600;
}

.referral-number {
    color: #4CA7DF;
    font-weight: bold;
    margin-top: 10px;
}

.reward-progress-bar-container {
    position: relative;
    width: 70%;
    justify-content: center;
    background-color: #e0e0e0;
    border-radius: 5px;
    margin: 20px auto; /* This centers the progress bar container */

  }
  
  .reward-progress-bar {
    margin-top: 35px;
    height: 20px;
    background-color: #4CA7DF;
    border-radius: 5px;
  }
  
  .milestone {
    position: absolute;
    top: -25px; /* Adjust as needed to position the milestone markers above the progress bar */
    transform: translateX(-50%);
    font-weight: 600;
    font-size: 12px;
    color: #4CA7DF; /* Milestone text color */
  }
  

.share-btn, .redeem-btn {
    font-size: 13px;
    font-weight: 600;
    background-color: #4CA7DF;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    margin-bottom: 10px;
    width: 100%;
}

.copy-btn {
    font-size: 13px;
    font-weight: 600;
    background-color: #4CA7DF;
    color: #fff;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    /* margin-bottom: 10px; */
    width: 20%;
}

.referral-code {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.referral-code input {
    color: #E9716B;
    font-weight: 500;
    flex: 1;
    font-size: 13px;
    text-align: center;
    border-radius: 20px;
    border-style: dashed;
    border-color: #9a9a9a;
    margin-right: 10px;
    padding: 10px;
    /* margin-bottom: 10px; */
}

/* Header of the popup */
.invite-popup-header {
    font-size: 15px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}
  
.invite-popup-message {
    font-size: 12px;
    text-align: center;
    /* color: rgb(199, 0, 0); */
}

.invite-popup-actions {
    display: flex;
    justify-content: space-between; /* This will put the buttons on either end */
    padding: 10px; /* Add some padding around the buttons */
}

.invite-popup-no-button {
    padding: 5px 10px;
    border-radius: 5px;
    background-color: rgb(199, 0, 0);
    border: none;
    color: white;
    font-size: 13px;
    font-weight: 600;
}
  
.invite-popup-yes-button {
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #FFB516;
    border: none;
    color: white;
    font-size: 13px;
    font-weight: 600;
}