.apple-card-display {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    border-radius: 8px;
    /* padding: 12px; */
    margin-top: 10px;
    margin-bottom: 10px;
    /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); */
  }

.apple-card-logo {
    width: 37px; /* Adjust size as needed */
  }

  .apple-card-info {
    flex-grow: 1;
    padding-left: 12px; /* Adjust spacing as needed */
  }

  .apple-card-number {
    font-size: 13px;
    /* font-weight: 600; */
    color: #4CA7DF;
  }