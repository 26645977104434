.events-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.calendar-container {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.react-calendar { 
  border: none !important;
  width: 100% !important;
  color: #222;
  font-family: 'Roboto' !important;
}

.react-calendar button {
  margin: 3px;
}

.react-calendar__month-view__days {
  display: grid !important;
  grid-template-columns: repeat(7, 14.2%); 
}

.react-calendar__tile {
  position: relative;
  padding: 10px 10px;
  border: none;
  border-radius: 7px;
  line-height: 20px !important;
  font-size: 14px;
  color: rgb(40, 40, 40);
  background-color: #fff;
  transition: background-color 0.3s;
  z-index: 1;  
}

.reserved-abbr {
  top: 10px;
  z-index: 2;
}

.react-calendar__tile abbr {
  position: relative;  
}

.react-calendar__tile--now {
  background: #FFB516;
  border: none;
  border-radius: 7px;
  /* font-weight: bold; */
  /* color: white; */
}

.react-calendar__tile:disabled {
  background-color: transparent !important; /* Using 'none' isn't valid for background-color, use 'transparent' instead */
  color: rgb(200, 200, 200);
  cursor: not-allowed; /* Optionally add a cursor style to indicate unavailability */
}

/* Ensure disabled tiles do not change on hover */
.react-calendar__tile:disabled:hover {
  background-color: transparent !important; /* Using 'none' isn't valid for background-color, use 'transparent' instead */
  color: rgb(200, 200, 200);
}

.react-calendar__navigation {
  display: flex;
  height: 0px !important;
  justify-content: space-between;
  align-items: center;
}

.react-calendar__navigation button {
  flex-grow: 0.5;
  color: black;
  text-align: center;
  background: none;
  border: none;
  font-size: 15px;
}

abbr[title] {
  margin-top: 10px;
  margin-bottom: 10px;
  display: inline-block;
  width: 50px;
  height: 20px;
  text-align: center;
  font-size: 0;
  vertical-align: middle;
}

abbr[title]::before {
  display: block;
  font-size: 13px;
}

abbr[title="Monday"]::before { content: "M"; }
abbr[title="Tuesday"]::before { content: "T"; }
abbr[title="Wednesday"]::before { content: "W"; }
abbr[title="Thursday"]::before { content: "T"; }
abbr[title="Friday"]::before { content: "F"; }
abbr[title="Saturday"]::before { content: "S"; }
abbr[title="Sunday"]::before { content: "S"; }

.legend {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 13px;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.history-dot, .today-dot, .booked-dot, .events-dot {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
}

.history-dot { background-color: rgb(200, 200, 200); }
.today-dot { background-color: #FFB516; }
.booked-dot { background-color: #E9716B; }
.events-dot { background-color: skyblue; }

.history-tile, .today-tile, .booked-tile, .events-tile {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 7px;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.history-tile {
  background-color: rgba(128, 128, 128, 0.6); /* grey with 0.6 opacity */
  color: white;
}

.react-calendar__tile--now { 
  background-color: #FFB516 !important; /* #FFB516 with 0.6 opacity */
  color: white;
}

.react-calendar__tile--hasActive:enabled:hover, .react-calendar__tile--hasActive:enabled:focus {
  background: #E9716B !important;
  color: white;
}

.react-calendar__tile--hasActive {
  background: #E9716B !important;
  color: white;
}

.react-calendar__tile--active { 
  background-color: #E9716B !important; /* #FFB516 with 0.6 opacity */
  color: white;
}

.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
  background-color: #4173fe !important;
  color: white;
}

.booked-tile {
  background-color: #E9716B !important;
  color: white !important;
}

.events-tile {
  background-color: rgba(135, 206, 235, 0.6); /* skyblue with 0.6 opacity */
}

.recent-visits { margin-bottom: 40px; }
.visit-item { margin-bottom: 10px; }
.visit-date { font-weight: bold; }
.visit-dog, .visit-description { margin-left: 10px; }

.news-feed { margin-bottom: 40px; }
.event-item { margin-bottom: 10px; }
.event-date { font-weight: bold; }
.event-title { margin-left: 10px; }

.events-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.event-details-header {
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

.event-details-item {
  display: flex;
  font-size: 13px;
  font-weight: 400;
  margin-left: -15px;
  padding: 3px 0;
  /* justify-content: space-between; */
  /* text-align: center; */
}

.date {
  margin-right: 5px;
}

.event-details-item div.date {
  flex-basis: 80px;  /* Fixed width for date */
  flex-grow: 0;
  /* margin-right: 10px !important; */
}

.event-details-item div.title {
  flex-basis: 100px;  /* Estimated width for title. Adjust accordingly */
  flex-grow: 0;
}

.event-details-item div.description {
  flex-basis: 150px;  /* Estimated width for description. Adjust accordingly */
  flex-grow: 0;
}

.event-details-pet-name {
  font-size: 13px;
  font-weight: 400;
}

.event-details-item button {
  margin-right: 15px;
  margin-bottom: 10px;
  background: none;
  border: none;
  color: #4CA7DF;
  font-size: 12px;
  font-weight: 500;
}

.event-details-message {
  font-size: 13px;
  font-weight: 400;
  text-align: center;
}

.booking-hr {
  margin: 15px;
}

.console-booking-button {
  font-weight: bold;
  font-size: 13px;
  background-color: #47B2E7;
  color: white;
  width: 70px;
  height: 30px;
  margin-left: 10px;
  margin-bottom: 30px;
  border-radius: 10px;
  border: none;
}