.buy-gift-card-hero-image {
    width: 100%;
  }
  
  .buy-gift-card-container {
    padding: 20px;
    /* background-color: #e6f7ff; */
    max-width: 400px;
    margin: 0 auto;
  }
  
  .buy-gift-card-header {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    color: #454545;
  }
  
  .buy-gift-card-paragraph {
    font-size: 13px;
    text-align: center;
    margin: 10px;
    /* font-weight: 600; */
    margin-bottom: 30px;
    color: #454545;
  }
  
  .buy-gift-card-display-container {
    align-items: center;
    border-radius: 20px;
    padding: 10px 15px;
    /* box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2); */
    /* margin: 10px 5px; */
    margin-bottom: 35px;
    transition: background-color 0.3s;
  }
  
  .buy-gift-card-image {
    width: 100%;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    border-radius: 40px;
  }

  .buy-gift-card-form {
    text-align: center;
  }

  .buy-gift-card-form label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
  }

    .amount-buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
        margin-bottom: 30px;
    }

    .amount-buttons button {
        font-size: 13px;
        font-weight: 600;
        background-color: #E9716B;
        color: #fff;
        padding: 10px 20px;
        border: none;
        border-radius: 20px;
        cursor: pointer;
        margin-bottom: 10px;
    }

    .amount-btn.selected {
      background-color: #FFB516;
    }

    .amount-buttons input, .recipient-email-container input {
        font-size: 13px;
        font-weight: 600;
        padding: 7px 7px;
        border: 2px solid #E9716B;
        border-radius: 20px;
        cursor: pointer;
    }

    .custom-amount-input {
        width: 80px;
        height: 40px;
    }

    .recipient-email-container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
    }

  
  .buy-btn {
    font-size: 13px;
    font-weight: 600;
    background-color: #A17FD4;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    margin-bottom: 10px;
    width: 100%;
  }
  
  .redeem-btn {
    font-size: 13px;
    font-weight: 600;
    background-color: #E9716B;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    margin-bottom: 10px;
    width: 100%;
  }
  
  .highlight-buy-gift-card {
    color: #FFB516;
    font-weight: 600;
  }