.friends-backdrop {
  background-color: #4CA7DF;
}

.friends-container {
  padding: 10px;
}

.friends-search-container {
  margin-top: 50px;
}

.friends-search-input {
  margin-left: 10px;
  margin-bottom: 10px;
  width: 60%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 12px;
}

.friends-search-button {
  font-size: 12px;
  font-weight: bold;
  background-color: #FFB516;
  color: #fff;
  padding: 10px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px auto;
  margin-left: 10px;
  width: 30%;
}

.friends-card-container-one {
  align-items: center;
  background-color: white;
  border-radius: 20px;
  padding: 10px 15px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  margin: 5px 5px;
  transition: background-color 0.3s;
}

.friends-arrow-image {
  width: 13px;
  margin-left: 5px;
  margin-bottom: 1px;
}

.friends-header {
  font-size: 18px;
  text-align: left;
  margin-left: 23px;
  font-weight: 600;
  color: white;
}

.friends-card-header {
  font-size: 13px;
  font-weight: 600;
  color: #3f3f3f;
}

.friends-card-paragraph {
  font-size: 11px;
  margin-top: -5px;
  /* font-weight: 600; */
  color: #3f3f3f;
}

.friends-suggestion-list {
  font-size: 12px;
  font-weight: 600;
  color: #E9716B;
}

.friends-breakline {
  height: 3px;
  margin: 0 auto;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.2;
}

.friends-card-token-container {
  display: flex;
  justify-content: left;
  /* margin-bottom: 10px; */
}

.friends-card-token-image {
  width: 50px;
  margin: 1px;
  border-radius: 25px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.friends-card-token-amount {
  font-size: 45px;
  font-weight: 600;
  margin-left: 10px;
  margin-top: 5px;
  color: #FFB516;
}

.friends-card-history-paragraph {
  font-size: 11px;
  margin-top: 10px;
  /* font-weight: 600; */
  color: #3f3f3f;
}

.friends-card-container-two {
  background-image: url('../../../../../assets/Dashboard/Profile/components/Friends/friendsContainerTwoBackground.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  padding: 10px 15px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  margin: 5px 5px;
  margin-bottom: 20px;
  transition: background-color 0.3s;
}

.friends-refer-header {
  margin-top: 20px;
  font-size: 15px;
  font-weight: 600;
  color: #3f3f3f;
}

.friends-refer-paragraph {
  font-size: 11px;
  margin-top: -5px;
  width: 50%;
  /* font-weight: 600; */
  color: #3f3f3f;
}

.friends-refer-learn-paragraph {
  font-size: 11px;
  margin-top: 10px;
  font-weight: 600;
  color: #4CA7DF;
  margin-bottom: 20px;
}

.friends-container-two {
  background-image: url('../../../../../assets/Dashboard/Profile/components/Wallet/containerTwoBackdrop.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
  margin-top: -1px;
  padding: 20px;
}


.friends-list-header {
  margin-top: 40px;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #3f3f3f;
}

.friends-list-paragraph {
  font-size: 12px;
  margin-top: -5px;
  color: #3f3f3f;
}

.friends-list-button {
  font-size: 14px;
  font-weight: bold;
  background-color: #E9716B;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  margin: 5px auto;
  width: 50%;
}

.friends-list-friend {
  display: flex;
  /* justify-content: start; */
  align-items: center;
}

.friends-list-name {
  font-size: 14px;
  margin-bottom: -2px;
  color: #3f3f3f;
}

.friends-list-image {
  width: 45px;
  margin: 5px;
  margin-right: 10px;
  /* border-radius: 50%; */
}

.friends-list-empty {
  font-size: 13px;
  font-weight: 600;
  margin-top: 10px;
  text-align: center;
  color: #E9716B;
}