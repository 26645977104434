@media only screen and (max-width: 768px) {
  .mobile-only {
    display: block;
  }

  .desktop-only {
    display: none;
  }
}

.btn {
  background-color: #FFB516;
  border: #FFB516;
}

hr {
  border: none;
  height: 3px;
  background-color: #9a9a9a7d; /* Or any color you prefer */
  opacity: 0.25;
  margin-top: 30px;
  margin-bottom: 30px;
}

p {
  margin-bottom: 10px;
}

/* Prevents zoom on mobile devices */
/* @media screen and (max-width: 600px) {
  input, select, textarea {
      font-size: 16px !important; 
  }
} */

/* Prevent images dragging */
img {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Standard syntax */
}