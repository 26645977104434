/* .booking-backdrop {
    margin-bottom: 150px;
} */

.booking-container {
    width: 100%;
    max-width: 600px;
    padding: 20px;
    border-radius: 10px;
    background-color: #ffffff;
}

.booking-hero-image {
    width: 100%;
}

.booking-header, .booking-footer {
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
    padding: 10px 0;
}

.booking-header h3 {
    font-size: 17px;
    text-align: center !important;
    font-weight: 600;
}

.booking-section h2 {
    font-size: 15px !important;
    font-weight: 600;
}

.booking-section {
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: center;

}

.booking-section-selectedPetForGrooming {
    overflow: hidden;
    transition: max-height 2s ease-in-out;
}

.booking-section-selectedPetForGrooming.open {
    /* max-height: 900px; */
}

.booking-section-selectedPetForGrooming.close {
    max-height: 0px;
}

.booking-section-selectedPetForTraining {
    overflow: hidden;
    transition: max-height 2s ease-in-out;
}

.booking-section-selectedPetForTraining.open {
    max-height: 900px;
}

.booking-section-selectedPetForTraining.close {
    max-height: 0px;
}

.booking-section h2 {
    font-size: 15px;
    margin-left: 15px;
    margin-bottom: 10px;
    text-align: left;
}

.booking-section label {
    display: block;
    margin-bottom: 10px;
}

.booking-section input[type="text"], .booking-section input[type="time"], .booking-section textarea {
    width: 100%;
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 15px;
    font-size: 13px;
    text-align: center;
    /* border-color: #FFB516; */
}

.booking-section textarea {
    resize: vertical;
    min-height: 80px;
    width: 300px;
    margin-left: 5px;
}

.booking-container button {
    padding: 10px 15px;
    margin: 3px;
    border: none;
    border-radius: 10px;
    font-size: 13px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.pet-selection {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}

.pet-button {
    width: 100px;
    height: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.pet-button- {
    color: black;
}

.pet-button-selected {
    /* background-color: #FFB516; */
    color: #ffffff;
    /* border: 2px solid #FFB516; */
    transform: scale(1.02);
    transition: transform 0.3s ease, margin 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
    background-color: #FFB516;
}

.pet-photo {
    object-fit: cover;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-bottom: 7px;
}

.pet-name {
    margin: 0;
    font-size: 14px;
    text-align: center;
}

.pet-type {
    margin: 0;
    color: #595959;
    font-size: 11px;
    text-align: center;
}

/* Default style */
.calendar-button {
    /* background-color: #FFFFFF; or any default color you want */
    /* color: #000000; or any default color you want */
    /* any other default styles you want */
}

/* Change color when button is active (i.e., being clicked or touched) */
.calendar-button-selected {
    transform: scale(1.02);
    background-color: #FFB516;
    color: #ffffff;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
}

.food-preference-button-selected {
    transform: scale(1.02);
    background-color: #FFB516;
    color: #ffffff;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
}

.food-preference button {
    margin-right: 10px; /* Adjust as needed */
}

.food-preference button:last-child {
    margin-right: 0; /* This ensures the last button does not have unnecessary margin on its right */
}

.additional-services button {
    margin-right: 10px; /* Adjust as needed */
}

.additional-services button:last-child {
    margin-right: 0; /* This ensures the last button does not have unnecessary margin on its right */
}

.service-button-selected {
    transform: scale(1.02);
    background-color: #FFB516;
    color: #ffffff;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
}

.skip-button {
    background-color: #007BFF;
    color: #ffffff;
}

.next-button {
    background-color: #007BFF;
    color: #ffffff;
}

.booking-container button:focus {
    outline: none;
}

/* .booking-container button:hover {
    background-color: #FFB516;
    color: #ffffff;
} */

.skip-button:hover {
    background-color: #e0e0e0;
}

.tooltip {
    opacity: 1;
    background-color: #d60052;
    color: white;
    margin: 5px;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    z-index: 10;
}

.FaInfoCircle {
    color: #666;
    margin-left: 5px;
    cursor: pointer;
}

.booking-time-container {
    display: flex;
    align-items: center; /* This will vertically center align the items */
    justify-content: center; /* This will align items to the start of the container */
    margin-bottom: 10px; /* Adjust as needed */
}

.booking-time-label {
    font-size: 13px;
    margin-left: 10px;
    margin-top: 10px;
    white-space: nowrap; /* Prevents the label from wrapping */
}

.booking-time-input {
    font-size: 13px !important;
    width: 115px !important;
    margin-left: 10px;
}

.booking-content-message {
    margin: 10px;
    /* background-color: #FFB516; */
    border-radius: 10px;
}

.booking-content-message2 {
    padding: 15px;
    text-align: center;
}

.booking-content-button-container {
    text-align: center;
    margin-bottom: 30px;
}

.booking-content-message-button {
    font-weight: bold;
    font-size: 14px;
    background-color: #d60052;
    color: white;
    width: 170px;
    height: 45px;
    border-radius: 20px;
    border: none;
}

/* Generic button style within HotelBooking component to set text color */
.booking-container .pet-button,
.booking-container .calendar-button,
.booking-container .food-preference-button,
.booking-container .service-button-,
.booking-container {
    color: rgb(20, 20, 20);
}

/* Style for buttons when they are selected to maybe maintain the text color */
.booking-container .pet-button-selected,
.booking-container .food-preference-button-selected,
.booking-container .calendar-button-selected {
    color: white;
}

.booking-calendar-container {
    margin-top: 20px;
}

.booking-availability-container {
    margin-top: 20px;
}

.booking-availability-text {
    font-size: 13px;
}

.booking-additional-button-container {
    padding: 10px;
}

  
.options, .additional-services {
    text-align: center;
    padding: 5px;
}

.haircut-style {
    text-align: center;
    margin: 15px;
}

.booking-timeslot-text {
    font-size: 14px;
    color: #E9716B;
}