.cart-backdrop {
    background-color: #4CA7DF;
}

.cart-container {
    padding: 15px;
    max-width: 600px;
    margin: auto;
}

.cart-list {
    padding: 0;
}

.cart-item-container {
    position: relative;
    align-items: center;
    background-color: white;
    border-radius: 20px;
    padding: 10px 15px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
    margin-bottom: 15px;
    transition: background-color 0.3s;
}

.cart-item-highlight {
    font-weight: 600;
    /* color: #FFB516; */
}

.cart-item-highlight2 {
    font-size: 13px;
    font-style: italic;
}

.cart-item-header-btn-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cart-remove-btn {
    font-size: 11px;
    color: #C70000;
    background: none;
    border: none;
    cursor: pointer;
    margin-top: -10px;
    margin-right: -5px;
}

.cart-remove-btn-image {
    width: 17px;
}

.cart-item-header {
    font-size: 13px;
}

.cart-item-date, .cart-item-time {
    font-size: 12px;
    color: #333;
}

.cart-item-price-container {
    position: absolute;
    bottom: 10px; /* Adjust as needed for your layout */
    right: 20px; /* Adjust as needed for your layout */
}

.cart-item-original-price-strike {
    text-decoration: line-through;
    color: #d60000; /* Adjust color as needed */
    margin-right: 5px;
}


.cart-item-price {
    font-size: 13px;
    color: #333;
}

.cart-item-discounted-price {
    font-size: 13px;
    color: #333;
    /* font-weight: bold; */
}

.cart-item-discounted-price-highlight {
    color: #d60000;
    text-decoration: line-through;
}

.cart-total-container {
    background-image: url('../../../assets//Dashboard/Profile//components/Cart/containerBackdrop.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
    margin-top: -1px;
    text-align: right;
    padding: 20px;
}

.cart-total {
    font-size: 14px;
    font-weight: 600;
    margin-top: 40px;
}

.cart-savings {
    font-size: 12px;
}

.cart-savings-highlight {
    color: #d60000;
}

.cart-item-options-header {
    font-size: 13px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 0px;
}

.cart-item-options-details {
    margin-bottom: 10px;
}

.cart-item-options-details li {
    margin-left: -20px;
    font-size: 12px;
}

.cart-item-options-message {
    margin-left: 10px;
    font-size: 12px;
}

.cart-empty-message {
    font-size: 14px;
    text-align: center;
    color: white;
}

.cart-checkout-btn {
    font-size: 13px;
    font-weight: bold;
    background-color: #4CA7DF;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 5px;
    width: 110px;
}

.cart-checkout-btn:hover {
    background: #3889bc;
}

.cart-breakline {
    height: 2px;
    margin: 0 auto;
    color: inherit;
    border: 0;
    border-top: var(--bs-border-width) solid;
    opacity: 0.2;
    margin-bottom: 10px;
}

.cart-item-breakline {
    height: 2px;
    color: inherit;
    border: 0;
    border-top: var(--bs-border-width) solid;
    opacity: 0.2;
    margin-left: -25px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.addons-list {
    list-style-type: circle; /* removes bullet points */
    margin-left: -5px; /* aligns with the rest of the addons */
}

.cart-menu-background {
    background-color: rgba(227, 227, 227, 0.778);
  }

  .cart-menu-header {
    font-size: 14px;
    color: white;
    text-align: center;
    margin-top: 20px;
}

.cart-menu-button-container {
    margin: 0 auto; /* This will center the button container if it is a block-level element */
}
  
  .cart-button-image-container {
    /* background-color: rgb(210, 210, 210); */
    margin: 0 auto; /* This will center the button container if it is a block-level element */
    width: 90px;
    /* height: 50px; */
    background-color: white;
    text-align: center;
    border-radius: 10px;
  }
  
  .cart-menu-button-image {
    width: 50%;
    padding: 3px;
    margin: 5px;
  }
  
  .cart-menu-button-title {
    text-align: center;
    font-size: 12px;
    color: white;
    font-weight: 500;
    margin-top: 5px;
  }