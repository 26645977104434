.membership-profile {
    /* Style your main container */
  }
  
  .membership-family-title {
    margin: 30px;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: #A66D4F;
  }
  
  .membership-family-member-section {
    /* Style the container for the family members */
  }
  
  .membership-family-member {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* align-items: center; */
    /* margin: 5px; */
    /* padding: 10px; */
    /* Additional styles */
    background-color: white;
    border-radius: 20px;
    padding: 10px 15px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    margin: 15px 10px;
    transition: background-color 0.3s;
  }
  
  .membership-family-member-image {
    object-fit: cover;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    margin-left: 5px;
  }
  
  .membership-family-member-details {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .membership-family-member-name {
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
    /* Style the family member's name */
  }
  
  .membership-family-member-type {
    margin-top: -10px;
    font-size: 10px;
    font-weight: 400;
    color: #A66D4F;
  }
  
  .membership-family-member-button {
    background-color: #A66D4F;
    border: none;
    border-radius: 10px;
    color: white;
    font-size: 12px;
    margin-right: 5px;
    padding: 5px 10px; /* Add padding for better touch area and appearance */
    cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
    transition: background-color 0.3s; 
  }

  .membership-family-member-button:hover {
    background-color: #e6a700; /* Slightly darker color on hover */
}

.membership-family-add-new {
    background-color: #FFFFFF; /* White background to stand out */
    color: #FFB516; /* Color to match the theme */
    border: 2px solid #FFB516; /* Solid border to make it pop a bit */
    border-radius: 20px; /* Rounded corners */
    padding: 10px 20px; /* Top/bottom and left/right padding */
    font-size: 16px; /* Larger font size */
    font-weight: bold; /* Bold font weight */
    text-align: center; /* Center text */
    display: inline-block; /* Allows for text centering */
    width: calc(100% - 40px); /* Full width minus padding */
    box-sizing: border-box; /* Ensures padding doesn't affect width */
    margin-left: 20px; /* Aligns with the rest of the content */
    cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
    transition: all 0.3s ease; /* Transition for hover effects */
    text-decoration: none; /* No underline on text */
}

.membership-family-add-new:hover {
    background-color: #FFB516; /* Background color on hover */
    color: white; /* Text color on hover */
    border-color: #FFB516; /* Border color on hover */
}

  .membership-profile-breakline {
    height: 1px;
    margin: 0 5px;
    color: inherit;
    border: 0;
    border-top: var(--bs-border-width) solid;
    opacity: 0.2;
  }
  
  .membership-family-add-new {
    /* Style the add new family member button */
    display: block;
    margin-top: 20px;
    /* Additional styles */
  }
  
  .contact-info-form {
    /* Style the form to update contact information */
  }
  
  .input-group {
    margin-bottom: 10px;
    /* Additional styles */
  }
  
  .membership-family-save-changes {
    /* Style the save changes button */
  }
  
.badges-container {
    display: flex;
    flex-direction: row; /* ensures badges are in a row */
    gap: 10px; /* adjust as needed, adds space between badges */
    margin-left: 30px;
}

  .badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.375rem;
    margin-right: 0.5em;
  }

  .membership-verified {
    background-color: #E9716B; /* A deep purple to signify exclusivity */
    color: #ffffff; /* White text for contrast */
    border: 1px solid #E9716B; /* Solid border for a more defined look */
  }
  
  .daycare-verified {
    background-color: #ffdf85; 
    color: #805300; 
    border: 1px solid #ffdf85; /* Solid border for a more defined look */
  }
  
  .vaccination-verified {
    background-color: #85ffaf; 
    color: #005229; 
    border: 1px solid #85ffaf; /* Solid border for a more defined look */
  }

  .membership-unverified {
    background-color: #E9716B; /* A lighter purple for unverified */
    color: #ffffff; /* Deep purple text to match the verified badge */
    border-color: #E9716B; /* Dashed border to indicate it's not yet verified */
    opacity: 0.2; /* Slightly translucent */
  }

  .daycare-unverified {
    background-color: #fff3cd; /* Lighter background for unverified */
    color: #806b00; /* Lighter text color for unverified */
    border-color: #ffdf85; /* Border color same as verified background */
    opacity: 0.2; /* Optional: reduces opacity for unverified badges */
  }
  
  .vaccination-unverified {
    background-color: #e6ffec; /* Lighter background for unverified */
    color: #003d1a; /* Lighter text color for unverified */
    border-color: #85ffaf; /* Border color same as verified background */
    opacity: 0.2; /* Optional: reduces opacity for unverified badges */
  }

  .membership-tier-header {
    font-size: 13px;
    margin-left: 5px;
  }

  .membership-tier-startDate {
    font-size: 11px;
  }

  .membership-tier-endDate {
    margin-left: 5px;
    font-size: 11px;
    width: 15%;
  }

  .membership-tier-li {
    margin-left: -10px;
    font-size: 11px;
    list-style: circle;
  }

  .membership-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%; /* Ensure the actions div takes full width */
  }
  
  .membership-renew-button {
    background-color: #FFB516;
    border: none;
    border-radius: 10px;
    color: white;
    font-size: 12px;
    font-weight: 600;
    margin-right: 5px;
    padding: 5px 10px; /* Add padding for better touch area and appearance */
    cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
    transition: background-color 0.3s; 
  }

  .membership-update-button {
    background-color: #FFB516;
    border: none;
    border-radius: 10px;
    color: white;
    font-size: 12px;
    font-weight: 600;
    margin-right: 5px;
    padding: 5px 10px; /* Add padding for better touch area and appearance */
    cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
    transition: background-color 0.3s; 
  }