.faq-component {
    margin: 20px 0;
  }
  
  .faq-item {
    margin-bottom: 20px;
  }
  
  .faq-question {
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .faq-answer {
    padding: 15px;
    color: white;
    background-color: #4CA7DF;
    border-radius: 20px;
    font-size: 14px;
  }
  