html {
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0.1px;
}

body {
}

.container {
  max-width: 2000px;
  margin: 0 auto;
  padding: 15px;
}

input, select {
  text-align: center;
}

/* @media (min-width: 800px) {

  body {
    max-width: 800px;
    margin: 0 auto;
  }

  .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 15px;
  }
} */

ul {
  list-style: none; /* Remove bullet points */
}
