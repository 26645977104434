.payment-methods-section {
    padding: 10px;
}

.payment-method {
    display: flex;
    align-items: center;
    padding: 7px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.payment-method:hover {
    background-color: #f1f1f1; /* Light gray background on hover */
}

.payment-method-image {
    width: 40px;
    height: 30px;
    margin-right: 15px;
}

.payment-method-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
}

.cardName-cardDefault {
    display: flex;
}

.default-badge-image {
    width: 25px;
    margin-left: 5px;
}

.payment-method-name {
    font-size: 14px;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 2px;
}

.payment-method-type {
    font-size: 14px;
    color: #999;
    margin-bottom: 0px;
}

.payment-method-badges-container {
    display: flex;
    gap: 10px;
}

.payment-method-badge {
    display: inline-block;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 500;
    background-color: #e6e6e6;
    border-radius: 10px;
}

.payment-method-breakline {
    height: 1px;
    color: inherit;
    border: 0;
    border-top: var(--bs-border-width) solid;
    opacity: 0.2;
    margin: 10px 0;
}

.add-payment-method-button-container {
    text-align: right;
}

.add-payment-method-button {
    font-weight: bold;
    font-size: 14px;
    background-color: #47B2E7;
    color: white;
    width: 150px;
    height: 35px;
    margin-top: 20px;
    border-radius: 10px;
    border: none;
}

.add-payment-method-button:hover {
    background-color: rgb(28, 116, 170);
}

.payment-method-message {
    text-align: center;
}
