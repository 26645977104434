.grooming-background-one-container {
    background-image: url('../../../../assets/Store/Services/pageBackground.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: right top;
}

@media (max-width: 767px) {
    .grooming-background-one-container {
        background-image: url('../../../../assets/Store/Services/pageBackgroundMobile.png');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: right top;
    }
}

.grooming-container {
    margin: 20px;
    margin-top: 80px;
}

.grooming-page-icon {
    width: 35px;
    margin-bottom: 15px;
}

.grooming-page-heading {
    font-size: 35px;
    font-weight: 300;
    color:rgba(0, 0, 0, 0.719);
    text-align: left;
}

.grooming-page-paragraph {
    font-size: 15px;
    color:rgba(0, 0, 0, 0.719);
    text-align: left;
}

.centered-container {
    display: flex;
    justify-content: center;
}

.grooming-page-button {
    font-weight: bold;
    font-size: 14px;
    background-color: #47B2E7;
    color: white;
    width: 200px;
    height: 45px;
    border-radius: 18px;
    border: none;
    margin: 20px;
}

.grooming-page-main-photo {
    width: 100%;
}


.grooming-background-two-container {
    background-image: url('../../../../assets/Store/Services/pageSubBackground.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: right top;
}

@media (max-width: 767px) {
    .grooming-background-two-container {
        background-image: url('../../../../assets/Store/Services/pageSubBackgroundMobile-1.png');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: right top;
        min-height: 900px;
    }
}

.grooming-subpage-heading {
    margin-top: 40px;
    font-size: 13px;
    font-weight: bold;
    color:rgba(0, 0, 0, 0.318);
    text-align: center;
}

.grooming-subpage-heading-3 {
    margin-top: 50px;
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 300;
    color:rgba(0, 0, 0, 0.719);
    text-align: center;
}

.grooming-services-item {
    display: flex;
    margin: 10px;
    margin-bottom: 20px;
    align-items: flex-start; /* Align items at the top */
}
  
.grooming-services-item-icon {
    width: 65px;
    margin-left: -35px !important;
    margin-right: 15px;
}

.grooming-services-item-heading {
    font-size: 20px;
    color:rgba(0, 0, 0, 0.824);
}

.grooming-services-item-paragraph {
    font-size: 14px;
    color:rgba(0, 0, 0, 0.824);
}


.grooming-safety-heading {
    margin-top: 10px;
    font-size: 13px;
    font-weight: bold;
    color:rgba(0, 0, 0, 0.318);
    text-align: center;
}

.grooming-safety-heading-3 {
    margin-top: 50px;
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 300;
    color:rgba(0, 0, 0, 0.719);
    text-align: center;
}

.grooming-background-four-container {
    background-image: url('../../../../assets/Store/Services/pageSubBackground.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
}

@media (max-width: 767px) {
    .grooming-background-four-container {
        background-image: url('../../../../assets/Store/Services/pageSubBackgroundMobile-3.png');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center center;
    }
}


/* Responsive CSS */
@media (min-width: 400px) {
    .grooming-container {
        margin-top: 120px;
    }

    .grooming-page-heading {
        font-size: 38px;
        font-weight: 300;
    }
    
    .grooming-page-paragraph {
        font-size: 16px;
    }

    .grooming-background-two-container {
        min-height: 990px;
    }

    .grooming-subpage-heading {
        font-size: 15px;
    }
    
    .grooming-subpage-heading-3 {
        font-size: 23px;
        margin-bottom: 40px;
    }
    
    .grooming-services-item-heading {
        font-size: 22px;
    }
    
    .grooming-services-item-paragraph {
        font-size: 16px;
        margin-bottom: 20px;
    }
    
    .grooming-safety-heading {
        font-size: 15px;
    }
    
    .grooming-safety-heading-3 {
        font-size: 23px;
        margin-bottom: 40px;
    }
}