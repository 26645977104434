.hotel-background-one-container {
    background-image: url('../../../../assets/Store/Services/pageBackground.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: right top;
}

@media (max-width: 767px) {
    .hotel-background-one-container {
        background-image: url('../../../../assets/Store/Services/pageBackgroundMobile.png');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: right top;
    }
}

.hotel-container {
    margin: 20px;
    margin-top: 80px;
}

.hotel-page-icon {
    width: 35px;
    margin-bottom: 15px;
}

.hotel-page-heading {
    font-size: 35px;
    font-weight: 300;
    color:rgba(0, 0, 0, 0.719);
    text-align: left;
}

.hotel-page-paragraph {
    font-size: 15px;
    color:rgba(0, 0, 0, 0.719);
    text-align: left;
}

.centered-container {
    display: flex;
    justify-content: center;
}

.hotel-page-button {
    font-weight: bold;
    font-size: 14px;
    background-color: #47B2E7;
    color: white;
    width: 200px;
    height: 45px;
    border-radius: 18px;
    border: none;
    margin: 20px;
}

.hotel-page-main-photo {
    width: 100%;
}

.hotel-subpage-heading {
    margin-top: 50px;
    font-size: 13px;
    font-weight: bold;
    color:rgba(0, 0, 0, 0.318);
    text-align: center;
}

.hotel-subpage-heading-3 {
    margin-top: 40px;
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 300;
    color:rgba(0, 0, 0, 0.719);
    text-align: center;
}

.hotel-services-item {
    display: flex;
    margin: 10px;
    margin-bottom: 20px;
    align-items: flex-start; /* Align items at the top */
}
  
.hotel-services-item-icon {
    width: 65px;
    margin-left: -35px !important;
    margin-right: 15px;
}

.hotel-services-item-heading {
    font-size: 20px;
    color:rgba(0, 0, 0, 0.824);
}

.hotel-services-item-paragraph {
    font-size: 14px;
    color:rgba(0, 0, 0, 0.824);
}

.hotel-safety-heading {
    margin-top: 90px;
    font-size: 13px;
    font-weight: bold;
    color:rgba(0, 0, 0, 0.318);
    text-align: center;
}

.hotel-safety-heading-3 {
    margin-top: 50px;
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 300;
    color:rgba(0, 0, 0, 0.719);
    text-align: center;
}

/* Responsive CSS */
@media (min-width: 400px) {
    .hotel-container {
        margin-top: 120px;
    }

    .hotel-page-heading {
        font-size: 38px;
        font-weight: 300;
        margin-bottom: 10px;
    }
    
    .hotel-page-paragraph {
        font-size: 16px;
    }

    .hotel-background-two-container {
        background-image: url('../../../../assets/Store/Services/pageSubBackgroundMobile-1.png');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: right top;
    }

    .hotel-subpage-heading {
        font-size: 15px;
    }
    
    .hotel-subpage-heading-3 {
        font-size: 23px;
        margin-bottom: 40px;
    }
    
    .hotel-services-item-heading {
        font-size: 22px;
    }
    
    .hotel-services-item-paragraph {
        font-size: 16px;
        margin-bottom: 20px;
    }
    
    .hotel-safety-heading {
        font-size: 15px;
    }
    
    .hotel-safety-heading-3 {
        font-size: 23px;
        margin-bottom: 40px;
    }

    .hotel-background-four-container {
        background-image: url('../../../../assets/Store/Services/pageSubBackgroundMobile-3.png');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center center;
    }
}

@media screen and (min-width: 668px) {
    .hotel-container {
        margin-top: 150px;
    }

    .hotel-page-icon {
        width: 45px;
        margin-left: 40px;
        margin-bottom: 15px;
    }

    .hotel-page-heading {
        font-size: 43px;
        font-weight: 300;
        max-width: 400px;
        margin-left: 40px;
    }
    
    .hotel-page-paragraph {
        font-size: 20px;
        max-width: 500px;
        margin-left: 40px;
    }

    .hotel-page-button {
        font-size: 16px;
        width: 200px;
        height: 50px;
        margin: 25px;
    }

    .hotel-page-main-photo {
        width: 100%;
    }

    .hotel-background-two-container {
        background-image: url('../../../../assets/Store/Services/pageSubBackground.png');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: right top;
    }
    
    .hotel-subpage-heading {
        font-size: 18px;
    }
    
    .hotel-subpage-heading-3 {
        font-size: 25px;
        margin-bottom: 40px;
    }

    .hotel-services-item-icon {
        width: 65px;
        margin-left: 0px !important;
        margin-right: 25px;
    }
    
    .hotel-services-item-heading {
        font-size: 25px;
    }
    
    .hotel-services-item-paragraph {
        font-size: 20px;
    }

    .hotel-safety-heading {
        font-size: 18px;
    }
    
    .hotel-safety-heading-3 {
        font-size: 25px;
        margin-bottom: 60px;
    }

    .hotel-background-four-container {
        background-image: url('../../../../assets/Store/Services/letsGetStartedBackground.png');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center center;
    }
}

@media screen and (min-width: 1024px) {
    .hotel-container {
        margin-top: 450px;
    }

    .hotel-page-icon {
        width: 55px;
        margin-left: 140px;
        margin-bottom: 15px;
    }

    .hotel-page-heading {
        font-size: 55px;
        font-weight: 300;
        max-width: 600px;
        margin-left: 140px;
    }
    
    .hotel-page-paragraph {
        font-size: 25px;
        max-width: 700px;
        margin-left: 140px;
    }

    .hotel-page-button {
        font-size: 20px;
        width: 250px;
        height: 60px;
        margin: 25px;
    }

    .hotel-page-main-photo {
        max-width: 50%; /* Adjust this value to control the size */
        display: block; /* Makes margin auto possible for centering */
        margin: 0 auto; /* Centers the image horizontally */
    }

    .hotel-background-two-container {
        background-image: url('../../../../assets/Store/Services/pageSubBackgroundDesktop.png');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: right top;
    }
    
    .hotel-subpage-heading {
        font-size: 18px;
    }
    
    .hotel-subpage-heading-3 {
        font-size: 25px;
        margin-bottom: 40px;
    }

    .hotel-services-item-icon {
        width: 65px;
        margin-left: 0px !important;
        margin-right: 25px;
    }
    
    .hotel-services-item-heading {
        font-size: 25px;
    }
    
    .hotel-services-item-paragraph {
        font-size: 20px;
    }

    .hotel-safety-heading {
        font-size: 18px;
    }
    
    .hotel-safety-heading-3 {
        font-size: 25px;
        margin-bottom: 60px;
    }

    .hotel-background-four-container {
        background-image: url('../../../../assets/Store/Services/letsGetStartedBackground.png');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center center;
    }



}