.impact-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 15px;
}

.impact-hero-image {
    width: 100%;
}

.impact-how-many-animals-header {
    font-size: 15px;
    font-weight: bold;
    color: rgb(46, 46, 46);
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;
}

.impact-how-many-animals-header-bold {
    font-weight: bold; /* Example: makes the number bold */
    color: #FFB516; /* Example: sets the color of the number to red */
  }
  
  .animal-grid {
    text-align: center;
    margin: 15px;
    margin-left: 36px;
    margin-right: 35px;
    /* padding: 5px; */
  }

  .animal-image {
    width: 35px;
    margin: 5px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .impact-container-without-padding {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1px;
}


  .impact-how-to-impact-background {
    background-color: rgba(227, 227, 227, 0.778);
  }

  .impact-how-to-impact-header {
    font-size: 14px;
    font-weight: bold;
    color: rgb(46, 46, 46);
    text-align: center;
    margin-top: 20px;
    /* margin-bottom: -10px; */
}

.impact-menu-container {
    /* background-color: rgb(210, 210, 210); */
    /* margin-top: 25px !important;
    margin-bottom: 30px !important; */
  }

.impact-menu-button-container {
    margin: 0 auto; /* This will center the button container if it is a block-level element */
}
  
  .impact-button-image-container {
    /* background-color: rgb(210, 210, 210); */
    margin: 0 auto; /* This will center the button container if it is a block-level element */
    width: 90px;
    /* height: 50px; */
    background-color: white;
    text-align: center;
    border-radius: 10px;
  }
  
  .impact-menu-button-image {
    width: 50%;
    padding: 3px;
    margin: 5px;
  }
  
  .impact-menu-button-title {
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    margin-top: 5px;
  }

  .impact-did-you-know {
    margin: 15px;
  }

  .impact-did-you-know-header {
    font-size: 15px;
    font-weight: 600;
  }

  .impact-did-you-know-paragraph1 {
    font-size: 12px;
    color: rgb(128, 128, 128);
    margin-bottom: 25px;
  }

  .impact-did-you-know-item {
    display: flex;
    align-items: flex-start; /* Align items to the top */
    margin-bottom: 15px;
  }
  
  .impact-did-you-know-icon {
    flex-shrink: 0;
    margin-right: 15px; /* Adds some space between the icon and the text */
    width: 55px; /* Set the width of the icon */
  }  

  .impact-did-you-know-paragraph2 {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .impact-did-you-know-paragraph2Sub {
    font-size: 12px;
    color: rgb(128, 128, 128);
  }
  